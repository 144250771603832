import React from 'react';
import {
    Page,
    BlockTitle,
    Block,
} from 'framework7-react';

import PlNavbar  from '../../shared/navbar/navbar';

export default class extends React.Component {
  render() {
    return (
  <Page>
    <PlNavbar/>
    <div style={{width:'100%',maxWidth:'740px',marginLeft:'auto',marginRight:'auto'}}>
      <BlockTitle large>Leiebetingelser</BlockTitle>
      
      <BlockTitle medium>A) PRIS, BETALING OG EKSTRA KOSTNADER</BlockTitle>
      <Block>
        <p>1. Alle våre priser er inkludert merverdiavgift med mindre annet er spesifisert. </p>
        <p>2. Leier kan pådra seg ekstra kostnader som er rimelige innenfor hendelsens natur, og fastsettes av PartyLyd dersom:</p>
        <ul>
          <li>Utstyr ikke leveres tilbake, eller leveres tilbake i merkbart dårligere stand enn før leieperioden.</li>
          <li>Leier ikke melder fra om skader som vedkommende burde hatt kjennskap til etter leieperioden.</li>
          <li>Tilbakelevering skjer senere enn avtalt.</li>
          <li>Leier ikke låser døren etter avhenting eller levering av utstyr.</li>
          <li>Andre klanderverdige forhold fra leiers side som medfører økonomisk tap for utleier.</li>
          <li>Ekstra kostnader faktureres eller betales til PartyLyd sitt Vippsnummer (<i>538607</i>).</li>
        </ul>
        <p>
        3. Bestillingen betales direkte med Vipps, Bankkort, eller innen 10 dager etter leieperioden med faktura. I enkelte tilfeller kan faktura ha forfall før leieperioden begynner.
        </p>
        <p>
        4. Betaling med faktura er forbeholdt personer over 18 år. Personer under 18 år må betale ved bestilling.
        </p>
        <p>
        5. Alminnelige bestemmelser fra kjøpsloven som ikke er spesifisert i disse betingelsene gjelder ved leie hos PartyLyd.
        </p>
        <p>
        6. Leier plikter å betale for hele den bestilte perioden uavhengig om utstyret blir levert tilbake før perioden løper ut.
        </p>
      </Block>

      <BlockTitle medium>B) UTLEVERING</BlockTitle>
      <Block>
        <p>1. Det er leiers ansvar å tilby tilstrekkelig med opplysninger for å muliggjøre levering.</p>
        <p>1.2 Ansvar for utstyret blir overtatt av leier etter utstyret blir plassert på avtalt sted, eller leier åpner døren til selvbetjent lager. PartyLyd har ansvar for utstyret først etter det er blitt avhentet av en representant fra PartyLyd, eller utstyret blir avlevert på lager med låst dør.</p>
        <p>2. Leier har økonomisk ansvar for alt utstyr på selvbetjent lager fra døren låses opp til lageret er tomt for folk og døren er låst igjen.</p>
        <p>3. PartyLyd har ikke økonomisk ansvar for klanderverdige forhold forbundet med utleie eller levering. Leier kan kreve opp til full refusjon avhengig av omstendighetene.</p>
      </Block>

      <BlockTitle medium>C) AVBESTILLING OG REKLAMASJON</BlockTitle>
      <Block>
        <p>
          1. Leier har rett på full refusjon dersom bestillingen avbestilles innen 5 dager før leieperioden. Leier plikter ikke å hente bestilt utstyr, og kan avbestille uten refusjon når som helst.
        </p>
        <p>
          2. Reklamasjoner må inneholde komplett informasjon om omstendighetene samt eventuell dokumentasjon. Den må sendes på mail.
        </p>
        <p>
          2.2 Leier skal melde fra om feil og mangel så snart de oppdages. Retten til å reklamasjon kan falle bort ved mistanke om mangelen er forårsaket av leier. Derfor kan det være for sent å melde fra etter endt leieperiode.
        </p>
      </Block>

      <BlockTitle medium>D) SELVBETJENT LAGER</BlockTitle>
      <Block>
        <p>
        1. Den ansvarlige leier har ansvar for bruk og eventuell misbruk av tilgang til lageret. Leier har ansvar for lageret inkludert alt inventar og økonomisk tap påført utleier fra enten leier eller en tredjepart dersom tilgang leier har fått blir benyttet for å gjøre dette mulig.
        </p>
        <p>
        2. Leier har ansvar for å gjøre seg kjent med og benytte relevant informasjon tilgjengelig på lageret for at utleien skal gå i orden. Hvis det er vesentlige uklarheter må utleier kontaktes for å kunne gjøre utleien mulig.
        </p>
        <p>
        3. Ved tilbakelevering skal leier følge instruksjonene for dette slik plakaten på døren sier at det skal gjøres. Ting må settes ryddig på riktig plass.
        </p>
        <p>
        4. Leier godkjenner at lageret er kameraovervåket og at opptak blir lagret inntil 30 døgn. Dersom opptaket viser brudd på leiebetingelsene kan det lagres i rimelig tid. Opptak kan brukes som bevis ved eventuell juridisk konflikt.
        </p>
        <p>
        5. Økonomisk tap påført utleier på grunnlag av utstyr som tilbakeleveres senere enn avtalt erstattes av leier.
        </p>
        <p>
        6. Dersom skade ikke rapporteres innen et døgn etter leie kan utleier fakturere et ekstra gebyr på inntil 400kr.
        </p>
        <p>
        7. Leier kan hente når som helst og levere tilbake når som helst innenfor kalenderdagene som utstyret er leid. PartyLyd kan gi pålegg om levering innen kl 14 og henting etter klokken 14 ved høy etterspørsel.
        </p>
      </Block>

      <BlockTitle medium>E) FORSIKRING</BlockTitle>
      <Block>
        <p>1. PartyLyd tilbyr forsikring som dekker all risiko (med enkelte unntak) i hele utstyrslisten til leier ved kjøp. Egenandel gjelder per objekt. Leier må betale egenandel i tråd med punkt 1.2.</p>
        <p>1.2 SATSER EGENANDEL</p>
        <ul>
          <li>Skade inntil 500 kr - 0 kr</li>
          <li>Skade inntil 1000 kr - 300 kr</li>
          <li>Skade inntil 1500 kr - 500 kr</li>
          <li>Skade inntil 2500 kr - 800 kr</li>
          <li>Skade inntil 5000 kr - 1200 kr</li>
          <li>Skade over 5000 kr - 1500 kr</li>
        </ul>
        <p>
        2. Forsikringen gjelder kun på utstyr som er levert tilbake. Ikke kast eller la være å levere tilbake med mindre annet er avtalt. Ta kontakt ved tyveri.
        </p>
        <p>
        3. Dersom leier ikke kjøper forsikring plikter vedkommende å dekke PartyLyds fulle økonomiske tap og kan motta et arbeidsgebyr på 250kr.
        </p>
        <p>
        4. Forsikringen gjelder ikke ved grov uaktsomhet eller bevisst påførte skader fra leier.
        </p>
      </Block>

      <BlockTitle medium>F) GENERELLE BESTEMMELSER</BlockTitle>
      <Block>
        <p>1. Det er ikke lov med fremleie av PartyLyd sitt utstyr.</p>
        <p>
        2. Leid utstyr er til enhver tid PartyLyds eiendom, og leier er ansvarlig for behandling av dette i løpet av leieperioden.
        </p>
        <p>
        3. Det leide utstyret skal ved tilbakelevering være i samme stand som ved henting/avlevering. All rengjøring, kveiling av kabler og tilbakestilling til samme stand som ved avhenting debiteres leier til Utleiers gjeldende timesatser.
        </p>
        <p>
        4. Leier skal gjøre alt vedkommende kan for å levere tilbake utstyr innen leieperioden er slutt. Leier må betale ekstra gebyr dersom utstyr blir levert tilbake for sent.
        </p>
        <p>
        5. PartyLyd har ikke noe ansvar for hvordan leier bruker utstyret som er leid. Leier skal opptre forsvarlig, i tråd med lover og svare til egen bruk.
        </p>
        <p>
        6. Både leier og utleier skal etter beste evne løse eventuelle problemer eller konflikter som skulle oppstå.
        </p>
        <p>
        7. Kunder som bestiller utstyr til redusert pris på bakgrunn av sponsoravtale forplikter seg til å følge opp sponsoravtalens fulle omfang. Dersom dette misligholdes er kunden nødt til å betale eventuelle avslag som har blitt gitt.
        </p>
        <p>
        8. PartyLyd kan når som helst endre priser, betingelser og gjeldende informasjon på PartyLyd.no.
        </p>
      </Block>

      <Block>
        <p><i>Betingelsene er sist oppdatert 10.07.2020 og nye justeringer har ikke tilbakevirkende kraft.</i></p>
      </Block>
    </div>

    <Block className="text-align-center">
      PARTYLYD AS | Org.nr. 919 580 127 MVA | kontakt@partylyd.no
    </Block>

  </Page>);
  }
};