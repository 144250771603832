import * as React from 'react';
import {
  Row,
  Col,
  Card,
  CardContent,
} from 'framework7-react';

import GoogleMapReact from 'google-map-react';

import './ourLocations.css'

export default class OurLocations extends React.Component {

    render() {
      return <div className="ourLocationsDiv">
        <div style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto'}}>
        <h3>Hvor finner du oss?</h3>

        <Row>
          <Col width="100" medium="50">
            <Row>
              <Col width="100">
              <Card className="package-home-card elevation-6">
                  <CardContent>
                    <h2>Lørenskog</h2>
                    <h4>Oskar Braatens vei 6, 1472 Fjellhamar</h4>
                    <p>Lageret er <b>alltid åpent</b>, og du kan hente og levere når som helst på døgnet.</p>
                  </CardContent>
                </Card>
                </Col>
                <Col width="100">
                <Card className="package-home-card elevation-6">
                  <CardContent>
                    <h2>Vinterbro</h2>
                    <h4>Sundbyveien 90, 1407 Vinterbro</h4>
                    <p>Lageret er <b>alltid åpent</b>, og du kan hente og levere når som helst på døgnet.</p>
                  </CardContent>
                </Card>
                </Col>
                <Col width="100">
                <Card className="package-home-card elevation-6">
                  <CardContent>
                    <h2>Trondheim</h2>
                    <h4>Sørenga 9, 7032 Trondheim</h4>
                    <p>Lageret er <b>alltid åpent</b>, og du kan hente og levere når som helst på døgnet.</p>
                  </CardContent>
                </Card>
                </Col>
            </Row>
          </Col>

          <Col width="100" medium="50" style={{ height: '534px'}}>
              <Card style={{ height: '100%'}}>
                  <GoogleMapReact
                  options={{
                    scrollwheel: false,
                  }}
                  bootstrapURLKeys={{ key: 'AIzaSyCDPHH-wvKKmdyzOmBglWN0N7cxZUeGcPY' }}
                  defaultCenter={{lat: 61.275604,lng: 10.842633}}
                  defaultZoom={6}
                  >
                    <div 
                      lat={59.702155}
                      lng={10.758296}
                      className='mapMarker'
                      style={{marginLeft:'-15px'}}
                    >
                      Vinterbro
                    </div>
                    <div 
                      lat={59.937186}
                      lng={10.975009}
                      className='mapMarker'
                      style={{marginLeft:'-40px'}}
                    >
                      Lørenskog
                    </div>

                    <div 
                      lat={63.406430}
                      lng={10.418220}
                      className='mapMarker'
                      style={{marginLeft:'-40px'}}
                    >
                      Trondheim
                    </div>
                  </GoogleMapReact>
              </Card>
          </Col>
      </Row>
        </div>
      </div>;
    }
};