import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Page,
    Popup,
    Navbar,
    NavRight,
    Link,
    Block,
    BlockTitle,
    SkeletonBlock,
    List,
    ListItem,
    Icon,
    Row,
    Col,
    Button,
    Preloader,
    ListInput,
    Searchbar,
    Toggle,
    AccordionContent,
    AccordionItem,
    AccordionToggle

} from 'framework7-react';

import './main.css'

import {API_URL} from '../../../api'

import AddExtraItem from './addExtraItem';
import ChooseItemGroupe from './chooseItemGroupe';

import PlNavbar  from '../../shared/navbar/navbar';
import PlBottomBar  from '../../shared/bottomBar/bottomBar';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error:false,
      errClassPickDuration:false,
      errClassPickLocation:false,
      errClassPickDeliveryLocation:false,
      choosePickupLocationOpened:false,
      chooseDeliverAdrOpened:false,
      confirmDeliverAdrOpened: false,
      chooseMethodeOpened:false,
      finishOrderOpened: false,
      displayRentPeriode:false,
      errClassMethode: null,
      errClassUserTelNr: null,
      methode: null,
      pickupLocation: {id: null, name:null, adr:null},
      deliverAdr: null,
      returnDeliver:null,
      install: null,
      userTelNr: null,
      insuranceToggleValue: false,
      installToggleValue: false,
      optDelivery: {
        results: null,
        loading: false
      },
      delivery:{
        loading: true,
        address:null,
        distance:null,
        time:null,
        polyline:null,
        price:null,
        point:{
          lat: null,
          lng: null
        }
      },
      order:{
        periode:{
          start:null,
          end:null
        }
      },
      package:{
        name:null,
        price:{
          start:null,
          day:null,
          delay_fee:null,
          delay_per_day:null,
          insurance:null
        },
        img:null,
        desc:null,
        items:null,
        optionalItemsList:null,
        chooseLightItemsList:null
      },
      optionalItemsArr:[],
      chooseLightItemsArr:[]
    }
  }

  openMap(e,lat,lng){

    let url = 'https://www.google.com/maps/search/?api=1&query='+lat+','+lng;

    window.open(url, '_blank');

    e.stopPropagation();
    e.preventDefault();

  }

  relativeUrl(path){
    return this.$f7route.url + path;
  }
  bgImgStyle(path){
    return 'url('+path+')';
  }
  qtyText(qty){
    return <>Antall: <b>{qty}</b></>;
  }
  timeConverterMonth(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var months_short = ['jan','feb','mar','apr','mai','jun','jul','aug','sep','okt','nov','des'];
    var month = months_short[a.getMonth()];
    var date = a.getDate();
    var time = date + '. ' + month;
    return time;
  }
  sumDays(time1,time2){
     var diff = ((time2 - time1)/86400000) - 1;

     if(diff < 0){
       diff = 0;
     }
     return Math.round(diff);
  }
  sumDaysFormat(time1,time2){
     var diff = ((time2 - time1)/86400000);
    if(diff === 1){
        return diff + ' dag';
    }else{
        return Math.round(diff) + ' dager';
    }
  }
  sumRentPrice(){
    var sumDays = this.sumDays(this.state.order.periode.start,this.state.order.periode.end);
    var sumPrice = null;
    if(sumDays){
      sumPrice = this.state.package.price.start + this.state.package.price.day*sumDays;
    }else{
      sumPrice = this.state.package.price.start;
    }
    if(sumPrice){
      return <>{sumPrice.toLocaleString().replace(/,/g," ",)},-</>;
    }
    return '';
  }

  sumTotalPrice(){
    var sumDays = this.sumDays(this.state.order.periode.start,this.state.order.periode.end);
    var sumPrice = null;
    if(sumDays){
      sumPrice = this.state.package.price.start + this.state.package.price.day*sumDays;
    }else{
      sumPrice = this.state.package.price.start;
    }

    if(this.state.methode === 2 && this.state.deliverAdr && this.state.delivery.price){
      
      if(this.state.returnDeliver){
        sumPrice = sumPrice + this.state.delivery.price + this.state.delivery.return;
      }else{
        sumPrice = sumPrice + this.state.delivery.price;
      }

    }

    if(this.state.insuranceToggleValue){
      sumPrice = sumPrice + this.state.package.price.insurance;
    }
    
    if(this.state.installToggleValue){
      sumPrice = sumPrice + 300;
    }

    //add sum extra item
    sumPrice = sumPrice + this.sumExtraItems();

    if(sumPrice){
      return <>{sumPrice.toLocaleString().replace(/,/g," ",)},-</>;
    }
    return '';
  }

  sumVat(){
    var sumDays = this.sumDays(this.state.order.periode.start,this.state.order.periode.end);
    var sumPrice = null;
    if(sumDays){
      sumPrice = this.state.package.price.start + this.state.package.price.day*sumDays;
    }else{
      sumPrice = this.state.package.price.start;
    }

    if(this.state.methode === 2 && this.state.deliverAdr && this.state.delivery.price){
      
      if(this.state.returnDeliver){
        sumPrice = sumPrice + this.state.delivery.price + this.state.delivery.return;
      }else{
        sumPrice = sumPrice + this.state.delivery.price;
      }
      
    }

    if(this.state.insuranceToggleValue){
      sumPrice = sumPrice + this.state.package.price.insurance;
    }

    if(this.state.installToggleValue){
      sumPrice = sumPrice + 300;
    }

    //add sum extra item
    sumPrice = sumPrice + this.sumExtraItems();

    sumPrice = (sumPrice - Math.round(sumPrice/1.25));

    if(sumPrice){
      return <>{sumPrice.toLocaleString().replace(/,/g," ",)},-</>;
    }
    return '';
  }

  sumTotalPriceVal(){
    var sumDays = this.sumDays(this.state.order.periode.start,this.state.order.periode.end);
    var sumPrice = null;
    if(sumDays){
      sumPrice = this.state.package.price.start + this.state.package.price.day*sumDays;
    }else{
      sumPrice = this.state.package.price.start;
    }

    if(this.state.methode === 2 && this.state.deliverAdr && this.state.delivery.price){
      
      if(this.state.returnDeliver){
        sumPrice = sumPrice + this.state.delivery.price + this.state.delivery.return;
      }else{
        sumPrice = sumPrice + this.state.delivery.price;
      }
      
    }

    if(this.state.insuranceToggleValue){
      sumPrice = sumPrice + this.state.package.price.insurance;
    }
    
    if(this.state.installToggleValue){
      sumPrice = sumPrice + 300;
    }

    //add sum extra item
    sumPrice = sumPrice + this.sumExtraItems();

    return sumPrice;

  }

  sumExtraItems(){

    var sumDays = this.sumDays(this.state.order.periode.start,this.state.order.periode.end);
    let arr = this.state.optionalItemsArr;
    let sumPrice = 0;

    for (var i in arr) {
        
      let sum = 0;
        if(sumDays){
          sum = arr[i].price.start + arr[i].price.day*sumDays;
        }else{
          sum = arr[i].price.start;
        }
  
        sum = sum * arr[i].qty;
        sumPrice = sumPrice + sum;
    }
    
    return sumPrice;

  }

  renderMethodeText(){
    if(this.state.methode === 1){
      return <><b>Hent selv</b></>;
    }
    if(this.state.methode === 2){
      if(this.state.returnDeliver){
        return <><b>Lever og returner</b></>;
      }else{
        return <><b>Kun levering</b></>;
      }
    }
    return <>Trykk for å velge <b>henting</b> eller <b>levering</b></>;
  }
  renderMethodeCard(){
    if(this.state.methode === 1){
      return <Card style={{marginTop:'12px'}} className={`${this.state.errClassPickLocation}`}>
          <CardContent>
            <List>
              <ListItem 
                link="#"
                header="Hentested"
                title={
                  this.state.pickupLocation.id
                     ? (
                      <>
                        <b>{this.state.pickupLocation.name}</b> - {this.state.pickupLocation.adr}
                      </>
                       )
                    : (
                      <>Trykk <b>her</b> for å velge hentested</>
                    )
                }
                onClick={() => this.setState({ choosePickupLocationOpened : true })}
              />
            </List>
          </CardContent>
        </Card>;
    }
    if(this.state.methode === 2){
      return <Card style={{marginTop:'12px'}} className={`${this.state.errClassPickDeliveryLocation}`}>
          <CardContent>
            <List>
              <ListItem 
                link="#"
                header="Leveringsadresse"
                title={
                  this.state.deliverAdr
                     ? (
                      <>
                        <b>{this.state.delivery.address}</b>
                      </>
                       )
                    : (
                      <>Trykk for å velge leveringsadresse</>
                    )
                }
                onClick={() => this.setState({ chooseDeliverAdrOpened : true })}
              />
            </List>
          </CardContent>
        </Card>;
    }
    return <></>;
  }

  renderItemPrice(n){

      var sumDays = this.sumDays(this.state.order.periode.start,this.state.order.periode.end);
      var sumPrice = null;
      if(sumDays){
        sumPrice = n.price.start + n.price.day*sumDays;
      }else{
        sumPrice = n.price.start;
      }

      sumPrice = sumPrice * n.qty;
      
      if(this.state.order.periode.start){
          return <><b>kr. {sumPrice.toLocaleString().replace(/,/g," ",)},-</b></>;
      }else{
          return <>fra <b>kr. {sumPrice.toLocaleString().replace(/,/g," ",)},-</b></>;
      }

  }

  getUniqueArr(arr){
    const seen = new Set();
    return arr.filter(el => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return !duplicate;
    });
  }

  addExtraItem(n){
    var self = this;

    let arr = self.state.optionalItemsArr;
    arr.push(n);

    arr = self.getUniqueArr(arr);

    self.setState({optionalItemsArr:arr});

  }

  removeExtraItem(id){

    let arr = this.state.optionalItemsArr;
    
    arr = arr.filter(function(arr) { 
      return arr.id !== id; 
    });

    this.setState({optionalItemsArr:arr});

  }

  chooseQtyExtraItem(id,name,qty_max){
    var self = this;
    const app = this.$f7;

    let buttons_arr = [];

    for (var i = 0; i < qty_max; i++){
      const qty = i+1;
      buttons_arr.push({
        text: qty,
        cssClass: 'text-align-center',
        onClick: function(){self.updateQtyExtraItem(id,qty)}
      });
    }

    app.dialog.create({
      title: 'Velg antall',
      text: 'Velg hvor mange <b>'+name+'</b> du ønsker å leie?',
      cssClass: 'text-align-center',
      buttons: buttons_arr,
      verticalButtons: true,
    }).open();

  }

  updateQtyExtraItem(id,qty){
    let arr = this.state.optionalItemsArr;

    for (var i in arr) {
      if (arr[i].id === id) {
          arr[i].qty = qty;
         break; //Stop this loop, we found it!
      }
    }

    this.setState({optionalItemsArr:arr});

  }

  renderOptionalItemsList(){
    
    let arr = this.state.optionalItemsArr;
    
    if(arr.length > 0){
      return <>
        <BlockTitle style={{marginTop:'16px'}}>Tilleggsprodukter</BlockTitle>
        <Card>
            <CardContent>
                <List mediaList>
                {arr.map(n => (
                    <ListItem
                    key={n.id}
                    title={n.name}
                    subtitle={<>+ {this.renderItemPrice(n)}</>}
                    text={<span dangerouslySetInnerHTML={{ __html: n.desc}}></span>}
                    >
                    <Block style={{ backgroundSize: 'auto 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '70px', height: '70px', borderRadius: '10%',  backgroundImage: this.bgImgStyle(n.img) }} slot="media"></Block>
                    {n.opt_qty_max > 1 ? (
                      <Button onClick={(e)=>this.chooseQtyExtraItem(n.id, n.name, n.opt_qty_max)} style={{marginRight:'4px',fontSize:'14px'}} slot="after" small outline>{n.qty}<Icon material="keyboard_arrow_down" size="26px" style={{marginLeft:'2px',marginTop:'-2px',marginRight:'-8px'}}></Icon></Button>
                    ):(
                      <Button style={{marginRight:'4px',fontSize:'14px'}} slot="after" small outline>{n.qty}</Button>
                    )}
                    <Button onClick={()=>this.removeExtraItem(n.id)} slot="after" small fill><Icon material="delete" size="26px" style={{marginLeft:'-8px',marginTop:'-2px',marginRight:'2px'}}></Icon>Fjern</Button>
                    </ListItem>
                ))}
                </List>
            </CardContent>
        </Card>
      </>
    }

  }
  
  chooseLightItem(n){
    var self = this;
    const app = this.$f7;
    let arr = self.state.chooseLightItemsArr;

    if(arr.length > 2){
      app.dialog.alert('Dersom du ønsker å velge denne lampen, må du fjerne en annen lampe du har valg.','Du har valgt 3 lamper.');
    }else{
      arr.push(n);
      arr = self.getUniqueArr(arr);
      self.setState({chooseLightItemsArr:arr});
    }

  }

  removeLampItem(id){
    let arr = this.state.chooseLightItemsArr;
    arr = arr.filter(function(arr) { 
      return arr.id !== id; 
    });
    this.setState({chooseLightItemsArr:arr});
  }

  renderLightItemsList(){
    
    let arr = this.state.chooseLightItemsArr;
    
    if(arr.length > 0){
      return <>
        <BlockTitle style={{marginTop:'16px'}}>Valgfri lamper</BlockTitle>
        <Card>
            <CardContent>
                <List mediaList>
                {arr.map(n => (
                    <ListItem
                    key={n.id}
                    title={n.name}
                    subtitle={"Valgfri lampe"}
                    text={<span dangerouslySetInnerHTML={{ __html: n.desc}}></span>}
                    >
                      <Block style={{ backgroundSize: 'auto 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '70px', height: '70px', borderRadius: '10%',  backgroundImage: this.bgImgStyle(n.img) }} slot="media"></Block>
                      <Button onClick={()=>this.removeLampItem(n.id)} slot="after" small fill><Icon material="delete" size="26px" style={{marginLeft:'-8px',marginTop:'-2px',marginRight:'2px'}}></Icon>Fjern</Button>
                    </ListItem>
                ))}
                </List>
            </CardContent>
        </Card>
      </>
    }

  }

  discount_code = () => {
    return (
      <AccordionItem>
        <AccordionToggle>
          <Link style={{marginLeft:'12px', marginTop:'12px',fontSize:'16px'}}>
            Skriv inn kampanjekode
            <Icon material="keyboard_arrow_down" size="22px" style={{marginLeft:'2px'}}></Icon>
          </Link>
        </AccordionToggle>
        <AccordionContent>
          <Card className={`${this.state.errClassUserTelNr}`}>
            <CardContent>
              <List>
                <ListInput
                  label="Kampanjekode"
                  value={this.state.campaign_code}
                  onChange={(e) => this.setState({campaign_code:e.target.value,campaign_code_err_mgs:''})}
                  type="text"
                  placeholder="Skriv inn kampanjekode"
                  errorMessage={this.state.campaign_code_err_mgs}
                  errorMessageForce={this.state.campaign_code_err_mgs ? true : false}
                />
              </List>
            </CardContent>
          </Card>
          <Block style={{marginTop:'16px'}}>
            <Button onClick={() => this.checkCampaignCode()} large raised fill color="pink">Vis kampanjepris</Button>
          </Block>
        </AccordionContent>
      </AccordionItem>
    )
  }

  checkCampaignCode = () => {
    const self = this;
    const app = this.$f7;
    if(this.state.campaign_code){
      app.dialog.preloader('Vent litt');
      fetch(API_URL+'/api/store/campain/check?code='+self.state.campaign_code, {
        method: 'GET'
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        app.dialog.close();
        if(data.err_code === 0){
          if(data.valid){
            //do something
          }else{
            self.setState({campaign_code_err_mgs:"Kampanjekoden er ikke gyldig..."});
          }
        }
      }).catch(err => {
        app.dialog.close();
        self.setState({campaign_code_err_mgs:"Nettverksfeil, prøv på nytt"});
      })
    }
    
  }

  render() {
    return (
  <Page onPageBeforeRemove={this.onPageBeforeRemove.bind(this)}>
    <PlNavbar/>

    {this.state.error
        ? (
        <>
          <BlockTitle className="text-align-center">En feil har oppstått</BlockTitle>
          <Block className="text-align-center">
            <p>Vi kan ikke finne pakken du leter etter.</p>
          </Block>
        </>
        )
      : (
      <>
    
    <Row noGap style={{width:'100%',maxWidth:'940px',marginLeft:'auto',marginRight:'auto'}}>
      <Col width="100" medium="60">
        {this.state.loading
          ? (
      <>
        <Card style={{marginTop:'88px'}} className="skeleton-text skeleton-effect-pulse">
          <CardHeader className="no-border" style={{ height: '240px', backgroundColor: '#ccc'}}/>
          <CardContent>
            <p>Quisque eget vestibulum nulla. Quisque quis dui quis ex ultricies efficitur vitae non felis. Phasellus quis nibh hendrerit...</p>
          </CardContent>
        </Card>

        <BlockTitle medium>Innhold i pakken</BlockTitle>
        <Card className="skeleton-text skeleton-effect-pulse">
          <CardContent>
            <List mediaList v-if="loading">
              {[1,2,3].map(n => (
                <ListItem
                  key={n}
                  title="Lorem ipsum"
                  text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lobortis et massa ac interdum. Cras consequat felis at consequat hendrerit. Aliquam vestibulum vitae lorem ac iaculis. Praesent nec pharetra massa, at blandit lectus. Sed tincidunt, lectus eu convallis elementum, nibh nisi aliquet urna, nec imperdiet felis sapien at enim."
                >
                  <SkeletonBlock style={{ width: '70px', height: '70px', borderRadius: '10%' }} slot="media" />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </>
        )
      : (
      <>
        <BlockTitle large>{this.state.package.name}</BlockTitle>
        <Card className="package-home-card">
            <CardHeader
    className="no-border"
    valign="bottom"
    style={{ backgroundImage: this.bgImgStyle(this.state.package.img) }}
  />
          <CardContent>
            <p dangerouslySetInnerHTML={{__html: this.state.package.desc}}/>
          </CardContent>
        </Card>
        
        <BlockTitle medium>Innhold i pakken</BlockTitle>
        <Card>
          <CardContent>
            <List mediaList>
              {this.state.package.items.map(n => (
                <ListItem
                  key={n.id}
                  title={n.name}
                  text={<span dangerouslySetInnerHTML={{ __html: n.desc}}></span>}
                  after={this.qtyText(n.qty)}
                >
                  <Block style={{ backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '70px', height: '70px', borderRadius: '10%',  backgroundImage: this.bgImgStyle(n.img) }} slot="media"></Block>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </> 
      )
    }

    {this.renderLightItemsList()}

    {this.renderOptionalItemsList()}

    {this.state.package.chooseLightItemsList &&
      <ChooseItemGroupe chooseLightItem={this.chooseLightItem.bind(this)} optionalItems={this.state.chooseLightItemsArr} items={this.state.package.chooseLightItemsList}/>
     }

    {this.state.package.optionalItemsList &&
    <AddExtraItem addExtraItem={this.addExtraItem.bind(this)} optionalItems={this.state.optionalItemsArr} items={this.state.package.optionalItemsList} order={this.state.order}/>
    }

      </Col>
      <Col width="100" medium="40">
        {this.state.loading
          ? (
        <>
          <div style={{height:'89px'}}/>
        </>
        )
        : (
        <BlockTitle medium style={{marginTop:'41px',lineHeight:'40px',marginBottom:'8px'}}>
          Bestill nå:
          <span style={{float:'right',fontSize:'36px',marginTop:'-6px'}}>
          {this.state.displayRentPeriode
          ? (
            this.sumTotalPrice()
          )
          : (
            <><span style={{fontSize:'24px'}}>fra</span> {this.sumTotalPrice()}</>
          )}
          </span>
        </BlockTitle>
        )}
        <div style={{width:'calc(100% - 20px)', height:'1px', backgroundColor: '#888', marginLeft:'10px', marginRight:'10px'}}></div>
        
        <BlockTitle style={{marginTop:'20px'}}>Velg leieperiode</BlockTitle>


        <Card className={`${this.state.errClassPickDuration}`}>
          <CardContent>
            <List>
              <ListItem 
                link="#"
                header="Utleieperiode"
                title={
                  this.state.displayRentPeriode
                     ? (
                      <>
                        Fra <b>{this.timeConverterMonth(this.state.order.periode.start)}</b> til <b>{this.timeConverterMonth(this.state.order.periode.end)}</b> ({this.sumDaysFormat(this.state.order.periode.start,this.state.order.periode.end)})
                      </>
                       )
                    : (
                      <>Trykk <b>her</b> for å velge utleieperiode</>
                    )
                }
                onClick={(e) => {this.chooseRentPeriode(e)}}
              />
            </List>
          </CardContent>
        </Card>


      <BlockTitle>Velg levering eller henting</BlockTitle>

        <Card className={`${this.state.errClassMethode}`}>
          <CardContent>
            <List>
              <ListItem 
                link="#"
                header="Levering eller henting"
                title={
                 this.renderMethodeText()
                }
                onClick={() => this.setState({ chooseMethodeOpened : true })}
              />
            </List>
          </CardContent>
        </Card>

        {this.renderMethodeCard()}


        <BlockTitle>Ditt telefonnummer</BlockTitle>
        <Card className={`${this.state.errClassUserTelNr}`}>
          <CardContent>
            <List>
              <ListInput
                label="Ditt telefonnummer"
                value={this.state.userTelNr}
                onChange={(e) => this.updateUserTelNr(e)}
                type="tel"
                placeholder="Skriv inn ditt telefonnummer"
                errorMessage="Skriv inn ditt telefonnummer"
                required
                validate
                pattern="[0-9]*"
                clearButton
              />
            </List>
          </CardContent>
        </Card>

        {this.discount_code()}

        <div style={{width:'calc(100% - 20px)', height:'1px', backgroundColor: '#888', marginLeft:'10px', marginRight:'10px', marginTop:'20px'}}></div>
        
        <Block style={{marginTop:'16px'}}>
          <Button onClick={() => this.orderNowBtn()} large raised fill>Neste</Button>
        </Block>

        <Block>
          <p>Har du problemer med å bestille? Ta kontakt på epost til: <b>kontakt@partylyd.no</b> så hjelper vi deg.</p>
        </Block>
        
      </Col>
    </Row>
    </>
    )
    }

    <PlBottomBar/>


    <Popup opened={this.state.chooseMethodeOpened} onPopupClosed={() => this.setState({chooseMethodeOpened : false})}>
          <Page>
            <Navbar title="Velg levering eller henting">
              <NavRight>
                <Link popupClose>
                  <Icon material="close"></Icon>
                </Link>
              </NavRight>
            </Navbar>
            
            <Block>
              Velg <b>Jeg vil hente selv</b> dersom du ønsker å hente din bestilling selv på et av våre selvbetjente lagere. Du kan hente og levere bestillingen når som helst innenfor utleieperioden. <b>Det er ingen ekstra kostnader ved å hente selv.</b>
            </Block>
            <Card>
              <CardContent>
                  <List>
                    <ListItem
                      popupClose
                      link="#"
                      title={
                        <><b>Jeg vil hente selv</b></>
                      }
                      onClick={() => this.setState({choosePickupLocationOpened : true, errClassMethode: false, methode: 1, deliverAdr:false})}
                    />
                  </List>
              </CardContent>
            </Card>
            <br/>
            <div className="groupSeparator">Eller</div>

            <Block>
              Velg <b>Jeg ønsker levering</b> dersom du ønsker at vi skal levere og hente pakken hos deg. Vi kan også montere og senere demontere pakken for deg. <b>Det koster ekstra med levering og montering.</b> Prisen avhenger av avstanden fra vårt nærmeste lager til din adresse.
            </Block>
            <Card>
              <CardContent>
                  <List>
                    <ListItem
                      popupClose
                      link="#"
                      title={
                        <><b>Jeg ønsker levering</b></>
                      }
                      onClick={() => this.setState({chooseDeliverAdrOpened : true, errClassMethode: false, methode: 2, pickupLocation: {id: null, name:null, adr:null}})}
                    />
                  </List>
              </CardContent>
            </Card>
          </Page>
    </Popup>

    <Popup opened={this.state.chooseDeliverAdrOpened} onPopupClosed={() => this.setState({chooseDeliverAdrOpened : false})}>
      <Page>
        <Navbar title="Velg leveringsadresse">
          <NavRight>
            <Link popupClose>
              <Icon material="close"></Icon>
            </Link>
          </NavRight>
        </Navbar>

        <Block>
          For å gi deg en leveringspris, må vi vite hvor du ønsker pakken levert. Skriv inn adressen der du ønsker pakken levert i feltet under.
        </Block>
        
        <Block>
        <Searchbar
          id="searchAdrInput"
          disableButton={false}
          placeholder="Søk etter leveringsadresse"
          clearButton={true}
          onChange={(e) => this.searchDeliveryAdr(e)}
        ></Searchbar>
        </Block>

        {this.deliverAdrShowResults()}

      </Page>
    </Popup>

    <Popup opened={this.state.confirmDeliverAdrOpened} onPopupClosed={() => this.setState({confirmDeliverAdrOpened : false})}>
      <Page>
        <Navbar title="Levering til adresse">
          <NavRight>
            <Link popupClose>
              <Icon material="close"></Icon>
            </Link>
          </NavRight>
        </Navbar>
        {this.deliverAdrCalcPriceRender()}
      </Page>
    </Popup>

    <Popup opened={this.state.choosePickupLocationOpened} onPopupClosed={() => this.setState({choosePickupLocationOpened : false})}>
          <Page>
            <Navbar title="Velg hentested">
              <NavRight>
                <Link popupClose>
                  <Icon material="close"></Icon>
                </Link>
              </NavRight>
            </Navbar>
            <Block>
              <p>Velg hvilket lager du ønsker å hente din bestilling på. Du kan selv hente din bestilling hele døgnet, alle dager i uken.</p>
            </Block>

            <Card>
              <CardContent>
                  <List>
                    <ListItem
                      link="#"
                      title={
                        <><b>Lørenskog</b> - Oscar Braatens vei 6, 1472 Fjellhamar</>
                      }
                      after={<Button outline onClick={(e)=>this.openMap(e,59.937186,10.975009)}>Se på kart</Button>}
                      onClick={() => this.setState({choosePickupLocationOpened : false, errClassPickLocation: false, pickupLocation : {id:1, name: 'Lørenskog', adr : 'Oscar Braatens vei 6'} })}
                    />
                    <Link slot="after"></Link>
                  </List>
              </CardContent>
            </Card>

            <Card style={{marginTop:'16px'}}>
              <CardContent>
                  <List>
                    <ListItem
                      link="#"
                      title={
                        <><b>Trondheim</b> - Sørenga 9, 7032 Trondheim</>
                      }
                      after={<Button outline onClick={(e)=>this.openMap(e,63.406529,10.418760)}>Se på kart</Button>}
                      onClick={() => this.setState({choosePickupLocationOpened : false, errClassPickLocation: false, pickupLocation : {id:3, name: 'Trondheim', adr : 'Sørenga 9'} })}
                    />
                    <Link slot="after"></Link>
                  </List>
              </CardContent>
            </Card>
            <Card style={{marginTop:'16px'}}>
              <CardContent>
                  <List>
                    <ListItem
                      link="#"
                      title={
                        <><b>Vinterbro</b> - Sundbyveien 90, 1407 Vinterbro</>
                      }
                      after={<Button outline onClick={(e)=>this.openMap(e,59.702155,10.758296)}>Se på kart</Button>}
                      onClick={() => this.setState({choosePickupLocationOpened : false, errClassPickLocation: false, pickupLocation : {id:2, name: 'Vinterbro', adr : 'Sundbyveien 90'} })}
                    />
                    <Link slot="after"></Link>
                  </List>
              </CardContent>
            </Card> 
          </Page>
    </Popup>

     <Popup opened={this.state.finishOrderOpened} onPopupClosed={() => this.setState({finishOrderOpened : false})}>
      <Page>
        <Navbar title="Fullfør bestilling">
          <NavRight>
            <Link popupClose>
              <Icon material="close"></Icon>
            </Link>
          </NavRight>
        </Navbar>

        {this.state.finishOrderOpened && (
          <>
          <BlockTitle medium>Tillegg</BlockTitle>
          <BlockTitle style={{marginTop:'0'}}>Forsikring</BlockTitle>
          <Block>
            <p>Dersom du forsikrer ditt utleie, trenger du ikke å bekymre deg for om noen ved et uhell ødelegger en gjennstand i pakken. Forsikring koster <b>Kr. {this.state.package.price.insurance},-</b> i tillegg.</p>
          </Block>
          <Card>
            <CardContent style={{marginTop:'-20px'}}>
              <List simpleList>
                <ListItem>
                  <span>Jeg ønsker forsikring</span>
                  <Toggle value={this.state.insuranceToggleValue} onToggleChange={(e) => this.setState({insuranceToggleValue: e })}/>
                </ListItem>
              </List>
            </CardContent>
          </Card>
          
          {this.state.deliverAdr &&
          (
          <>
          <BlockTitle>Montering</BlockTitle>
          <Block>
            <p>Vi kan montere din bestilling på adressen den skal leveres. Montering koster <b>Kr. 300,-</b> i tillegg.</p>
          </Block>
          <Card style={{marginTop:'-20px'}}>
            <CardContent>
              <List simpleList>
                <ListItem>
                  <span>Jeg ønsker montering</span>
                  <Toggle value={this.state.installToggleValue} onToggleChange={(e) => this.setState({installToggleValue: e })}/>
                </ListItem>
              </List>
            </CardContent>
          </Card>
          </>
          )
          }

          <BlockTitle medium>Pris</BlockTitle>
          <Card>
            <CardContent>
              
              <List>
                <ListItem title="Pakkepris" after={<>{this.sumRentPrice()}</>}/>
                {this.sumExtraItems() > 0 && (
                <ListItem title="Tilleggsprodukter" after={<>{this.sumExtraItems().toLocaleString().replace(/,/g," ",)},-</>}/>
                )}
                {this.state.deliverAdr && (
                    this.state.returnDeliver
                      ? (
                        <ListItem title="Levering og returnering" after={<>{(this.state.delivery.price + this.state.delivery.return).toLocaleString().replace(/,/g," ",)},-</>}/>
                        )
                      : (
                        <ListItem title="Levering" after={<>{(this.state.delivery.price).toLocaleString().replace(/,/g," ",)},-</>}/>
                      )
                )}
                {this.state.installToggleValue && (
                <ListItem title="Montering" after={<>300,-</>}/>
                )}
                {this.state.insuranceToggleValue && (
                <ListItem title="Forsikring" after={<>{this.state.package.price.insurance},-</>}/>
                )}
              </List>

            </CardContent>
            <CardContent style={{borderTop:'5px solid var(--f7-theme-color)'}}>
              
              <List>
                <ListItem title={<><b>Totalt å betale</b><br/><span style={{fontSize:'13px',color:'#666'}}>(Totalsum inkludert {this.sumVat()} mva)</span></>}>
                <b style={{color:'#000'}}>{this.sumTotalPrice()}</b>
                </ListItem>
              </List>

            </CardContent>
          </Card>

          <Block>
            <Row tag="p">
              <Col tag="span">
                <Button
                  raised
                  fill
                  large
                  onClick={() => this.confirmOrder()}
                >
                  Bestill nå
                </Button>
              </Col>
            </Row>
          </Block>
        </>
        )}
      </Page>
    </Popup>

  </Page>);
  }

  searchDeliveryAdr(e){

    if(e.target.value.length > 5){
      fetch(API_URL+'/api/store/map_search_autocomplete?query='+e.target.value, {
        method: 'GET'
      })
      .then(response => {
        return response.json()
      })
      .then(data => {
        this.setState({
          optDelivery: {
            results: data.search_result,
            loading: false,
            error: false,
          }
        });
      })
      .catch(err => {
        this.setState({
          optDelivery: {
            results: null,
            loading: false,
            error: true,
          }
        });
      })
    }
    return false;
  }

  deliverAdrShowResults(){

    if(this.state.optDelivery.results) {
      
      if(this.state.optDelivery.results.length > 0){
      return <Card>
        <CardContent>
        <List mediaList>
        {this.state.optDelivery.results.map(n => (
          <ListItem
            link="#"
            title={n.main_text}
            subtitle={n.secondary_text}
            onClick={()=>this.deliverAdrCalcPrice(n.lat,n.lng)}
            />
        ))}
        </List>
        </CardContent>
        </Card>;
      }else{
        return <>
        <Block strong>
          <p>Obs, vi finner ikke din adresse.</p>
          <p>Prøv å skriv inn adressen på en annen måte, eller legg til flere søkeord. (Kommune, sted etc.)</p>
        </Block>
        <Block>
          <p>Dersom du fremdeles ikke ser din adresse, ta kontakt på telefon til:<b>414 96 901</b> eller epost til: <b>kontakt@partylyd.no</b>.</p>
        </Block>
        </>
      }

    }
    return false;

  }

  deliverAdrCalcPriceRender(){

    if(this.state.delivery.loading) {
      return <>
        <BlockTitle className="text-align-center">Vent litt</BlockTitle>
        <Block className="text-align-center">
          <Preloader></Preloader>
        </Block>
      </>
    }else{
    return <>
    <Block>
      <p>Din adresse er <b>{this.state.delivery.distance.text}</b> unna vårt nærmeste lager som tilbyr. ({this.state.delivery.depot})</p>
    </Block>
    {this.state.delivery.distance.value > 40000 ? 
    (
    <>
    <Block>
      <p>Vi kan desverre ikke levere lenger en 40 km fra vårt lager. Du kan fremdeles bestille pakken, og hente bestillingen selv. Vi har også flere selvbetjente lagere som du kan hente din bestilling fra.</p>
    </Block>
    <Card>
      <CardContent>
          <List>
            <ListItem
              popupClose
              link="#"
              title={
                <><b>Jeg vil hente selv</b></>
              }
              onClick={() => this.setState({choosePickupLocationOpened : true, errClassMethode: false, methode: 1})}
            />
          </List>
      </CardContent>
    </Card>
    </>
    ):(
    <>
    <Block>
      <p>Vi kan levere til <b>{this.state.delivery.address}</b> for <b>Kr. {this.state.delivery.price},-</b>.<br/><b>Du må selv levere tilbake utstyret</b> til vårt lager på <b>{this.state.delivery.depot}</b> når utleien er over.</p>
    </Block>
    <Card>
      <CardContent>
          <List>
            <ListItem
              popupClose
              link="#"
              title={
                <><b>Jeg ønsker kun levering</b></>
              }
              onClick={() => this.setState({confirmDeliverAdrOpened : false, errClassMethode: false, errClassPickDeliveryLocation: false, deliverAdr: true, returnDeliver: false})}
            />
          </List>
      </CardContent>
    </Card>
    <br/>
    <div className="groupSeparator">Eller</div>
    <Block>
      <p>Dersom du ønsker at vi både skal <b>levere</b> pakken når utleien starter, og <b>hente</b> pakken når utleien er over koster dette <b>Kr. {this.state.delivery.return},-</b> ekstra. Dette blir da totalt <b>Kr. {this.state.delivery.price + this.state.delivery.return},- for både levering og returnering</b>.</p>
    </Block>
    <Card>
      <CardContent>
          <List>
            <ListItem
              popupClose
              link="#"
              title={
                <><b>Jeg ønsker at dere leverer og returnerer</b></>
              }
              onClick={() => this.setState({confirmDeliverAdrOpened : false, errClassMethode: false, errClassPickDeliveryLocation: false, deliverAdr: true, returnDeliver: true})}
            />
          </List>
      </CardContent>
    </Card>
    <br/>
    <div className="groupSeparator">Eller</div>
    <br/>
    <Card>
      <CardContent>
          <List>
            <ListItem
              popupClose
              link="#"
              title={
                <><b>Jeg vil heller hente selv</b></>
              }
              onClick={() => this.setState({choosePickupLocationOpened : true, errClassMethode: false, methode: 1})}
            />
          </List>
      </CardContent>
    </Card>
    </>
    )}
    <br/>
    <br/>
    <br/>
    </>;
    }

  }

  deliverAdrCalcPrice(lat,lng){
    this.setState({
      chooseDeliverAdrOpened : false,
      confirmDeliverAdrOpened: true,
      delivery:{
        error: false,
        loading: true,
      }
    });

    fetch(API_URL+'/api/store/calc_delivery_cost?lat='+lat+'&lng='+lng, {
      method: 'GET'
    })
    .then(response => {
      return response.json()
    })
    .then(data => {
      this.setState({
        delivery:{
          error: false,
          loading: false,
          depot: data.depot,
          address:data.route.adress_name.end_address,
          point:{
            lat:data.route.markers.end_location.lat,
            lng:data.route.markers.end_location.lng
          },
          distance:{
            text:data.route.route_info.distance.text,
            value:data.route.route_info.distance.value
          },
          time:data.route.route_info.duration.text,
          polyline:data.route.polyline,
          price:data.price,
          return:data.return
        },
      });
    })
    .catch(err => {
      this.setState({
        delivery:{
          error: true,
          loading: false,
        }
      });
    })

  }

  updateUserTelNr(e){
    this.setState({
      errClassUserTelNr: null,
      userTelNr: e.target.value
    });
  }

  chooseRentPeriode(e){
    var self = this;
    const app = this.$f7;
    const $ = this.$$;

    var today = new Date();
    var yesterday = new Date().setDate(today.getDate() - 1);

    app.calendar.create({
      routableModals: false,
      openIn: 'customModal',
      backdrop: true,
      closeByOutsideClick:false,
      header: true,
      yearSelector: false,
      disabled: {
              to: yesterday
      },
      monthNames:['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August' , 'September' , 'Oktober', 'November', 'Desember'],
      dayNamesShort:['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
      dayNames:['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'],
      renderHeader: function () {
        return '<div class="calendar-header">'+
                '<div id="chooseRentPeriodeHeader" class="text-align-center">Velg <b>startdato</b> for leie</div>'+
              '</div>';
      },
      rangePicker: true,
      on: {
        change: function () {
          var selfChange = this;
          var value = this.getValue();
          if(value.length === 1){
            $('#chooseRentPeriodeHeader').html('Velg <b>sluttdato</b> for leie</div>');
          }else{
            
            let startTime = new Date(value[0]).getTime();
            let endTime = new Date(value[1]).getTime();

            if(endTime === startTime){
              endTime = endTime + 86400000;
              selfChange.setValue([value[0],endTime])
            }

            self.setState({
              displayRentPeriode:true,
              errClassPickDuration:false,
              order: {
                 periode:{
                  start: startTime,
                  end: endTime,
                 }
              }
            });
            
            setTimeout(function(){
              selfChange.close();
            },700);
            
          }
        },
        close: function () {
          this.destroy();
        }
      }
    }).open();;

  }

  orderNowBtn(){
    var self = this;
    const app = this.$f7;
    const $ = this.$$;

    if(!this.state.displayRentPeriode){
      self.setState({
        errClassPickDuration: 'cardErrorShaddow'
      });
    }
    if(!this.state.methode){
      self.setState({
        errClassMethode: 'cardErrorShaddow'
      });
    }
    if(!this.state.pickupLocation.id){
      self.setState({
        errClassPickLocation: 'cardErrorShaddow'
      });
    }

    if(!this.state.userTelNr){
      self.setState({
        errClassUserTelNr: 'cardErrorShaddow'
      });
    }

    if(!this.state.deliverAdr){
      self.setState({
        errClassPickDeliveryLocation: 'cardErrorShaddow'
      });
    }

    //Choose light
    if(this.state.package.chooseLightItemsList && this.state.chooseLightItemsArr){
      let lengthList = this.state.package.chooseLightItemsList.length || 0;
      let lengthArr = this.state.chooseLightItemsArr.length || 0;
      if(lengthList > 0 & lengthArr < 3){
        $('#chooseLight')[0].scrollIntoView({ behavior: 'smooth' });
        return false;
      }
    }
    
    //check if deliver date is posible
    let first_deliver_date = (new Date().getTime() + (1000*60*60*24*2));
    if(this.state.methode === 2 & this.state.order.periode.start < first_deliver_date){
      
      app.dialog.alert('Vi kan desverre ikke levere din bestilling. Du må velge en startdato minst 3 dager frem i tid for at vi skal kunne levere til deg.','Vi kan ikke levere.');

      return false;
    }

    if(this.state.pickupLocation.id && this.state.methode && this.state.displayRentPeriode && this.state.userTelNr){
      self.setState({ finishOrderOpened : true });
    }else if(this.state.displayRentPeriode && this.state.methode && this.state.deliverAdr && this.state.userTelNr){
      self.setState({ finishOrderOpened : true });
    }
    
  }

  componentDidMount(){
    this.loadXhr();
  }

  onPageBeforeRemove() {
    const self = this;
    // Destroy popup when page removed
    if (self.popup) self.popup.destroy();
    if (self.calendar) self.calendar.destroy();
  }

  loadXhr() {

    const pid = this.$f7route.params.id;

    fetch(API_URL+'/api/store/get_package', {
      method: 'POST',
      body: JSON.stringify({
        package: pid,
      })
    })
    .then(response => {
      return response.json()
    })
    .then(data => {

      if(data.err_code === 0){
        this.setState({
          loading:false,
          error:false,
          package:{
            id:data.id,
            name:data.name,
            price:data.price,
            img:data.img,
            desc:data.desc,
            items:data.items,
            optionalItemsList:data.optionalItems,
            chooseLightItemsList:data.chooseLightItems
          }
        });
      }else{
        this.setState({
          loading:false,
          error:true,
        });
      }
    })
    .catch(err => {
      this.setState({
        loading:false,
        error:true,
      });
    })

}
confirmOrder() {
  var self = this;
  const app = this.$f7;
  
  app.dialog.preloader('Vent litt');

  let insurance = function(){
    if(self.state.insuranceToggleValue){
      return 1
    }
    return 0
  }

  let install = function(){
    if(self.state.installToggleValue){
      return 1
    }
    return 0
  }

  //get optional array:
  const optionalItemsArr = this.state.optionalItemsArr;
  let optionalItems = [];

  for (let i = 0; i < optionalItemsArr.length; i++) {
    const obj = optionalItemsArr[i];
    optionalItems.push({id:obj.id,qty:obj.qty})
  }

  //get optional array:
  const lightItemsArr = this.state.chooseLightItemsArr;
  let lightItemsList = [];

  for (let i = 0; i < lightItemsArr.length; i++) {
    const obj = lightItemsArr[i];
    lightItemsList.push({id:obj.id,qty:obj.qty})
  }

  const body = JSON.stringify({
        methode: this.state.methode,
        pickupLocation: this.state.pickupLocation.id,
        deliverAdr: this.state.deliverAdr,
        delivery: {
          address: this.state.delivery.address,
          lat: this.state.delivery.point.lat,
          lng: this.state.delivery.point.lng,
          price: this.state.delivery.price,
          return: this.state.returnDeliver,
          returnPrice: this.state.delivery.return,
        },
        insurance: insurance(),
        install: install(),
        userTelNr: this.state.userTelNr,
        periode:{
            start:(this.state.order.periode.start/1000),
            end:(this.state.order.periode.end/1000)
        },
        package: this.state.package.id,
        optionalItems: optionalItems,
        lightItems: lightItemsList,
        price: this.sumTotalPriceVal(),
  });

  fetch(API_URL+'/api/order/new', {
    method: 'POST',
    body: body
  })
  .then(res => res.json())
  .then(
    (result) => {
      
      if(result.err_code === 0){
        
        app.dialog.close();
        app.popup.close();
        app.views.main.router.navigate('/order/'+result.url, {
          reloadAll: true
        });

      }else{
        
        app.dialog.close();
        app.popup.close();
        app.dialog.alert('Prøv igjen senere', 'En feil har oppstått');
        
      }
    },
    (error) => {
      
      app.dialog.close();
      app.popup.close();
      app.dialog.alert('Prøv igjen senere', 'En feil har oppstått');

    }
  )

}
};