import React from 'react';
import { Page, BlockTitle, Block, List, ListItem, ListInput, Card, CardContent, Button, Preloader } from 'framework7-react';

import {API_URL} from '../../../api'

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      r_1: 0,
      r_2: 0,
      i_1: '',
      i_2: ''
    };
  }

  updateI1(e){
    this.setState({
      i_1: e.target.value
    });
  }

  updateI2(e){
    this.setState({
      i_2: e.target.value
    });
  }

  updateR1(val){
    this.setState({
      r_1: val
    });
  }

  updateR2(val){
    this.setState({
      r_2: val
    });
  }


  xhrSendForm() {
    const self = this;
    const app = this.$f7;

    this.setState({
      loading: true,
      form_error: false,
    });

    const body = JSON.stringify({
      sid: this.$f7route.params.sid,
      i_1: this.state.i_1,
      i_2: this.state.i_2,
      r_1: this.state.r_1,
      r_2: this.state.r_2,
    });
    
    fetch(API_URL+'/api/order/feedback/send', {
      method: 'POST',
      body: body,
      headers : new Headers(),
    })
    .then(res => res.json())
    .then(
      (result) => {
        app.views.main.router.navigate('/order/'+self.$f7route.params.sid, {
          reloadAll: true
        });
      },
      (error) => {
        this.setState({
          loading: false,
          form_error: true,
        });
      }
    )

  }

  renderFbComplete(){
    return (
      <div style={{width:'100%',maxWidth:'540px',marginLeft:'auto',marginRight:'auto'}}>
        <h4 className="text-align-center">Takk for din tilbakemelding</h4>
        <Block>
          <p className="text-align-center">
            <b>Takk for at du valgte PartyLyd som utleier til din fest! Vi håper den ble fantastisk!</b>
          </p>
          <p className="text-align-center">
            Vi jobber kontinuerlig for å være norges beste på høytalerutleie og kundeservice. Vi vil derfor takke deg for at du svaret på vår undersøkelse.
          </p>
          <p className="text-align-center">
            <b>Tusen takk for hjelpen!</b>
          </p>
        </Block>
      </div>
    )
  }

  renderFeedBackForm(){
    return (
      <>
        <div style={{width:'100%',maxWidth:'540px',marginLeft:'auto',marginRight:'auto'}}>
          <h4 className="text-align-center">Gi oss en tilbakemelding</h4>
          <Block>
            <p className="text-align-center">
              <b>Takk for at du valgte PartyLyd som utleier til din fest! Vi håper den ble fantastisk!</b>
            </p>
            <p className="text-align-center">
              Vi jobber kontinuerlig for å være norges beste på høytalerutleie og kundeservice. Vi ønsker derfor å stille deg noen spørsmål om utleien. Vi håper du har ett minutt til å svare på noen få spørsmål.
            </p>
            <p className="text-align-center">
              Du svarer på denne siden. På forhånd takk for hjelpen!
            </p>
          </Block>
        </div>
        <div className="ourLocationsDiv" style={{backgroundColor:'#dfe4ea'}}>
          <div style={{width:'100%',maxWidth:'480px',marginLeft:'auto',marginRight:'auto'}}>

            {this.state.form_error &&
              <Card style={{marginTop:'30px'}}>
                  <CardContent style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                      <span style={{fontSize:'18px'}}>
                          En ukjent feil har oppstått. Din tilbakemelding ble ikke registrert.
                      </span>
                  </CardContent>
              </Card>
            }

            <h4>Hvor fornøyd er du med PartyLyd?</h4>
            <Card>
              <List id="rl-1">
                <ListItem radio title="Veldig fornøyd" name="r1" value="1" onClick={()=>this.updateR1(1)}></ListItem>
                <ListItem radio title="Litt fornøyd" name="r1" value="2" onClick={()=>this.updateR1(2)}></ListItem>
                <ListItem radio title="Middels fornøyd" name="r1" value="3" onClick={()=>this.updateR1(3)}></ListItem>
                <ListItem radio title="Litt misfornøyd" name="r1" value="4" onClick={()=>this.updateR1(4)}></ListItem>
                <ListItem radio title="Veldig misfornøyd" name="r1" value="5" onClick={()=>this.updateR1(5)}></ListItem>
              </List>
            </Card>

            <h4>Vil du anbefale PartyLyd til andre?</h4>
            <Card>
              <List>
                <ListItem radio title="Ja" name="r-2" value="1" onClick={()=>this.updateR2(1)}></ListItem>
                <ListItem radio title="Nei" name="r-2" value="2" onClick={()=>this.updateR2(2)}></ListItem>
              </List>
            </Card>
            <h4>Er det noe du er misfornøyd med?</h4>
            <Card>
              <List>
                <ListInput
                  type="textarea"
                  placeholder="Skriv inn svar her"
                  value={this.state.i_1}
                  onChange={(e) => this.updateI1(e)}
                />
              </List>
            </Card>
            <h4>Er det noe annet vi bør forbedre?</h4>
            <Card>
              <List>
                <ListInput
                  type="textarea"
                  placeholder="Skriv inn svar her"
                  value={this.state.i_2}
                  onChange={(e) => this.updateI2(e)}
                />
              </List>
            </Card>
            <Block>
            <Button large fill onClick={()=>this.xhrSendForm()}>
              Send inn
            </Button>
            </Block>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <Page>
            {this.state.loading
                ? (
                  <>
                    <BlockTitle className="text-align-center">Vent litt</BlockTitle>
                    <Block className="text-align-center">
                      <Preloader></Preloader>
                    </Block>
                  </>
                  )
                : (
                  this.state.error
                  ? (
                    <div style={{width:'100%',maxWidth:'540px',marginLeft:'auto',marginRight:'auto'}}>
                      <BlockTitle className="text-align-center">En feil har oppstått</BlockTitle>
                      <Block className="text-align-center">
                        <p>Skjekk om du har skrevet inn rett adresse.</p>
                        <p>Du kan også prøve å laste inn siden på nytt.</p>
                      </Block>
                      <div style={{width:'100%',maxWidth:'240px',marginLeft:'auto',marginRight:'auto'}}>
                        <Button onClick={()=>{window.location.reload()}} large outline>Last inn på nytt</Button>
                      </div>
                      <br/>
                      <Block className="text-align-center">
                        <p><b>Har du fremdeles problemer?<br/>Ta kontakt, så hjelper vi deg.</b></p>
                      </Block>
                      <div style={{width:'100%',maxWidth:'240px',marginLeft:'auto',marginRight:'auto'}}>
                      <Button href={'/kontakt'} raised fill large color="pink">Ta kontakt</Button>
                      </div>
                    </div>
                  ) : (
                    this.state.feedback === 0 ? (
                      this.renderFeedBackForm()
                    ) : (
                      this.renderFbComplete()
                    )
                  )
                )
              }
      </Page>
    );
  }

  componentDidMount(){
    this.loadXhr();
  }

  loadXhr() {
    const sid = this.$f7route.params.sid;
    
    fetch(API_URL+'/api/order/feedback', {
      method: 'POST',
      body: JSON.stringify({
        sid: sid
      }),
      headers : new Headers(),
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result.err_code === 0){
        this.setState({
          loading: false,
          feedback: result.feedback
        });
        }else{
          this.setState({
            loading: false,
            error: true,
          });
        }
      },
      (error) => {
        this.setState({
          loading: false,
          error: true,
        });
      }
    )

  }
}