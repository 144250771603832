import React from 'react';
import {
    Page,
    BlockTitle,
    Block,
} from 'framework7-react';

import PlNavbar  from '../shared/navbar/navbar';
import PlBottomBar  from '../shared/bottomBar/bottomBar';

export default class extends React.Component {
  render() {
    return (
  <Page>
    <PlNavbar/>
    <div style={{width:'100%',maxWidth:'740px',marginLeft:'auto',marginRight:'auto'}}>
      <BlockTitle large>Kontakt oss</BlockTitle>
      <Block>
        <p>Vi er her for å hjelpe deg! Vi hjelper deg dersom du har problemer med din bestilling, betaling eller montering. Vi kan også hjelpe deg med masse annet! <b>Lurer du på noe, ta kontakt!</b></p>
        <p><i>Er du en bedriftskunde, eller en annen med spesielle behov? Ta kontakt, så kan vi skreddersy egne pakker, eller gi egne tilbud!</i></p>
      </Block>
      <BlockTitle medium>Epost</BlockTitle>
      <Block>
        <p>Du kan ta kontakt med oss på epost til <b>kontakt@partylyd.no</b>. Dersom du kontakter oss vedrørende en bestilling, ber vi deg bruke ordrenummeret i emnefeltet.</p>
        <Block style={{fontSize:'16px'}}>
        <p>Epost - <b>kontakt@partylyd.no</b></p>
        </Block>
      </Block>
      <BlockTitle medium>Telefon</BlockTitle>
      <Block>
        <p>Dersom du kontakter oss vedrørende en bestilling, eller du har spørsmål om levering eller montering. Ber vi deg ta kontakt med det lageret din bestillig hører til. Du finner lageret du hører til under din bestilling.</p>
        <Block style={{fontSize:'16px'}}>
        <p>Oslo - <b>970 78 176</b></p>
        <p>Vinterbro - <b>414 96 901</b></p>
        <p>Trondheim - <b>468 19 933</b></p>
        </Block>
      </Block>
    </div>

    <PlBottomBar/>

  </Page>);
  }
};