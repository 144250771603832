import React from 'react';
import {
    Button,
    Card,
    Page,
    Block,
    BlockTitle,
    List,
    ListItem,
    Preloader,
    CardContent,
    CardHeader,
} from 'framework7-react';

import {API_URL} from '../../../api';

import Payment from '../../shared/checkout/payment';
import PlNavbar  from '../../shared/navbar/navbar';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      order: {}
    }
  }
  relativeUrl(path){
    return this.$f7route.url + path;
  }
  googelMapsAdr(){
    return "https://www.google.com/maps/dir//"+this.state.order.location.adr+"/@"+this.state.order.location.lat+","+this.state.order.location.lng+",16z/"
  }
  bgImgStyle(path){
    return 'url('+path+')';
  }
  qtyText(qty){
    return <>Antall: <b>{qty}</b></>;
  }
  formatBID(){
    let bID = this.state.order.bID;
    return bID.substring(0, 2) + '-' + bID.substring(2, 4) + '-' + bID.substring(4, 6);
  }
  timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['januar','februar','mars','april','mai','juni','juli','august','september','oktober','november','desember'];
    var days = ['søndag','mandag','tirsdag','onsdag','torsdag','fredag','lørdag'];
    var day = days[a.getDay()];
    var month = months[a.getMonth()];
    var date = "0" + a.getDate();
    var time = day + ', ' + date.substr(-2) + '. ' + month;
    return time;
  }
  formatRentPeriode(){
    var sumDays = this.sumDays(this.state.order.periode.start,this.state.order.periode.end)+1;
    var sumDaysText = "";
    if(sumDays === 1){
      sumDaysText = "totalt 1 dag";
    }else{
      sumDaysText = "totalt "+sumDays+" dager";
    }
    return <p>Din bestilling starter <b>{this.timeConverter(this.state.order.periode.start)}</b> og varer til <b>{this.timeConverter(this.state.order.periode.end)}</b> ({sumDaysText}).</p>;
  }

  unlockUnitConfirm(){
    const app = this.$f7;
    const self = this;

    app.dialog.create({
      title: 'Er du sikker?',
      text: 'Du må kun låse opp lageret når du skal hente din bestilling.<br/><br/><b>Du er ansvarlig for utstyret inne på lageret når det er ulåst.</b>',
      buttons: [
        {
          text: 'Avbryt',
          color: 'red'
        },
        {
          text: 'Lås opp',
          bold: true,
          onClick: function(){self.unlockUnit()}
        },
      ],
      on: {
        closed: function () {
          app.dialog.destroy();
        }
      }
    }).open();

  }

  unlockUnit(){
    const sid = this.$f7route.params.sid;
    const app = this.$f7;

    app.dialog.preloader('Låser opp');

    fetch(API_URL+'/api/storage-unit/unlock?id='+this.state.order.location.id+'&sid='+sid)
    .then(response => {
      return response.json()
    })
    .then(data => {
      
      app.dialog.close();

    })
    .catch(err => {
      app.dialog.close();
      app.dialog.alert('En feil har oppstått','En feil har oppstått');
    })

  }

  renderFeedbackCard(){
    let current_time = Math.round(new Date().getTime()/1000);

    if (this.state.order.feedback === 0 && current_time > ( this.state.order.periode.end ) ) {
    return(
      <>
        <BlockTitle medium>Gi oss en tilbakemelding</BlockTitle>
        <Card>
          <CardContent>
            <p>
              <b>Takk for at du valgte PartyLyd som utleier til din fest! Vi håper den ble fantastisk!</b>
            </p>
            <p>Vi jobber kontinuerlig for å være norges beste på høytalerutleie og kundeservice. Vi ønsker derfor å stille deg noen spørsmål om utleien. Vi håper du har ett minutt til å svare på noen få spørsmål.</p>
            <hr/>
            <Button  style={{marginTop:'12px'}} href={'./feedback'} raised fill large color="pink">Gi din tilbakemelding</Button>
          </CardContent>
        </Card>
      </>
    )
    }
  }

  renderInvoiceDownload(){
    return(
      <>
        <BlockTitle medium>Faktura</BlockTitle>
        <Card>
          <CardContent>
            <p>Du har motatt en faktura for din bestilling.</p>
            <p>Fakturaen er sendt til din epostadresse, og forfaller til betaling {this.timeConverter(this.state.order.periode.start + (60*60*24*7))}.</p>
            <p><i>Har du problemer med å finne fakturaen? Ta kontakt, så hjelper vi deg!</i></p>
          </CardContent>
        </Card>
      </>
    )
  }

  renderInvoiceWaitingReview(){
    return(
      <>
        <BlockTitle medium>Din bestilling godkjennes</BlockTitle>
        <Card>
          <CardContent>
            <p>Din bestilling har blitt registrert, men du kan ikke hente bestillingen før opplysningene du har oppgitt har blitt kontrollert og godkjent.</p>
            <p>Det kan ta noen dager til bestillingen har blitt godkjent, og du får automatisk varsel på sms når du har blitt godkjent.</p>
            <p><i>Har du ikke hørt fra oss? Eller har du andre spørsmål? Ta kontakt, så hjelper vi deg!</i></p>
            <Button href={'/kontakt?ref='+this.state.order.bID} raised fill large color="pink">Ta kontakt</Button>
          </CardContent>
        </Card>
      </>
    )
  }

  renderUnlockUnit() {
    let current_time = Math.round(new Date().getTime()/1000);

    if (this.state.order.paid === 1 && this.state.order.periode.start < current_time && current_time < this.state.order.periode.end + 86000 ) {
    return(
      <>
        <BlockTitle medium>Lås opp lager</BlockTitle>
        <Card>
          <CardContent>
            <p>Din leieperiode har startet, og du kan låse opp lageret for å hente din bestilling. Du må også låse opp lageret når du skal levere din bestilling. Husk å levere din bestilling innen <b>{this.timeConverter(this.state.order.periode.end)}</b>.</p>
            <hr/>
            <p>Trykk på knappen <b>Lås opp lager</b> for å låse opp lageret.</p>
            <Button onClick={()=>this.unlockUnitConfirm()} raised fill large color="pink">Lås opp lager</Button>
          </CardContent>
        </Card>
      </>
    )
    }
  }

  renderMethode1() {
    
    return(
      <>
        <BlockTitle medium>Hent din bestilling</BlockTitle>
        <Card>
          <CardContent>
            <p>Du må selv hente din bestilling på vårt lager på <b>{this.state.order.location.name}</b>. ({this.state.order.location.adr})</p>
            <p>Du kan hente din bestilling fra <b>{this.timeConverter(this.state.order.periode.start)}</b>, og bestilling må leveres tilbake innen <b>{this.timeConverter(this.state.order.periode.end)}</b>.</p>
          </CardContent>
          <hr/>
          <BlockTitle>Hvordan hente bestillingen.</BlockTitle>
          <Block>
          <ol className="custom-counter">
              <li>Når leieperioden starter, må du dra til vårt lager på <b>{this.state.order.location.name}</b>. <a className="external" href={this.googelMapsAdr()} target="_blank">Få veibeskrivelse</a></li>
              <li>Når du har kommet frem til lageret, kan du <b>låse opp</b> lageret og hente din bestilling. Du låser opp lageret ved å gå til denne siden, når leieperioden er startet, og følge instruksjonene.</li>
          </ol>
          </Block>
          <BlockTitle>Hvordan levere bestillingen.</BlockTitle>
          <Block style={{paddingBottom:'2px'}}>
          <ol className="custom-counter">
              <li>Når leieperioden er over, må du dra til vårt lager på <b>{this.state.order.location.name}</b>. <a className="external" href={this.googelMapsAdr()} target="_blank">Få veibeskrivelse</a></li>
              <li>Når du har kommet frem til lageret, kan du <b>låse opp</b> lageret og levere din bestilling. Du låser opp lageret ved å gå til denne siden, når leieperioden er startet, og følge instruksjonene.</li>
          </ol>
          </Block>
        </Card>
        {this.renderUnlockUnit()}
      </>
    )
  }
  
  renderMethode2() {
    return(
      <>
        {this.state.order.deliver.return === 1
        ? (
          <>
            <BlockTitle medium>Levering og henting</BlockTitle>
            <Card>
              <CardContent>
                <p>Du har bestilt levering til <b>{this.state.order.deliver.adr}</b>, du har også bestilt henting når utleien er ferdig.</p>
                <p>Vi vil ta kontakt med deg på telefon til <b>{this.state.order.tel}</b> for å avtale levering og henting.</p>
                <p><i>Har du ikke hørt fra oss innen {this.timeConverter(this.state.order.periode.start - 258000)} ta kontakt med oss. Du kan også ta kontakt med oss dersom du lurer på noe.</i></p>
                <Button href={'/kontakt?ref='+this.state.order.bID} raised fill large color="pink">Ta kontakt</Button>
              </CardContent>
            </Card>
          </>
        ) : (
          <>
           <BlockTitle medium>Levering</BlockTitle>
            <Card>
              <CardContent>
                <p>Du har bestilt levering til <b>{this.state.order.deliver.adr}</b>.</p>
                <p>Vi vil ta kontakt med deg på telefon til <b>{this.state.order.tel}</b> for å avtale levering.</p>
                <p>Du er selv ansvarlig for å levere tilbake utstyret til vårt lager</p>
                <p><i>Har du ikke hørt fra oss innen {this.timeConverter(this.state.order.periode.start - 258000)} ta kontakt med oss. Du kan også ta kontakt med oss dersom du lurer på noe.</i></p>
                <Button href={'/kontakt?ref='+this.state.order.bID} raised fill large color="pink">Ta kontakt</Button>
              </CardContent>
            </Card>
          </>
        )}
      </>
    )
  }

  renderExtraService(){
    if(this.state.order.install === 1 || this.state.order.insurance === 1 || this.state.order.methode === 2 ){
      
      var ExtraService = [];
      if(this.state.order.install === 1 ){
        ExtraService.push("montering");
      }
      if(this.state.order.insurance  === 1 ){
        ExtraService.push("forsikring");
      }

      if(this.state.order.methode === 2 ){
        if(this.state.order.deliver.return === 1){
          ExtraService.push("levering og returnering");
        }else{
          ExtraService.push("levering");
        }
      }
      
      var ExtraServiceStr = ExtraService.join(', ');
      var ExtraServiceStr = ExtraServiceStr.charAt(0).toUpperCase() + ExtraServiceStr.slice(1);

      return  <>
              <p>Tilleggstjenester:&nbsp;
                <b>{ExtraServiceStr}</b>
              </p>
            </>;
    }
  }

  order() {
    return(
      <>
        <BlockTitle medium>Din bestilling</BlockTitle>
        <Card>
          <CardHeader>
            {this.state.order.name}
          </CardHeader>
          <CardContent>
            {this.formatRentPeriode()}
            {this.renderExtraService()}
          </CardContent>
          <CardContent style={{borderTop:'5px solid var(--f7-theme-color)'}}>
          <List inset>
          {this.renderOrderItems()}
          </List>
          </CardContent>
        </Card>
      </>
    )
  }

  renderOrderItems() {
    return(
      <>
        <List mediaList style={{borderTop:'1px solid var(--f7-card-header-border-color)'}}>
          {this.state.order.items.map(n => (
            <ListItem
              key={n}
              title={n.name}
              text={<span dangerouslySetInnerHTML={{ __html: n.desc}}></span>}
              after={this.qtyText(n.qty)}
            >
              <Block style={{ backgroundSize: 'auto 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '70px', height: '70px', borderRadius: '10%',  backgroundImage: this.bgImgStyle(n.img) }} slot="media"></Block>
            </ListItem>
          ))}
        </List>
      </>
    )
  }

  renderDeliverPriceList() {
    return(
       this.state.order.deliver.return === 1
              ? (
                <ListItem title={<>Levering og returnering</>} after={this.formatPrice(this.state.order.price.deliver)}/>
              ) : (
                <ListItem title={<>Levering</>} after={this.formatPrice(this.state.order.price.deliver)}/>
              )
    )
  }

  renderTotalPrice() {

    return(
      <>
        <BlockTitle medium>Pris og betaling</BlockTitle>
        <Card>
          <CardContent>
            <List inset>
              <ListItem title={<>Pakkepris</>} after={this.totalPeriodePrice()}/>
              {this.state.order.price.optional_items > 0 &&
                <ListItem title={<>Tilleggsprodukter</>} after={this.formatPrice(this.state.order.price.optional_items)}/>
              }
              {this.state.order.price.deliver > 0 &&
                this.renderDeliverPriceList()
              }
              {this.state.order.price.insurance > 0 &&
                <ListItem title={<>Forsikring</>} after={this.formatPrice(this.state.order.price.insurance)}/>
              }
              {this.state.order.price.install > 0 &&
                <ListItem title={<>Montering</>} after={this.formatPrice(this.state.order.price.install)}/>
              }
              {this.state.order.price.invoice_fee > 0 &&
                <ListItem title={<>Fakturagebyr</>} after={this.formatPrice(this.state.order.price.invoice_fee)}/>
              }
            </List>
          </CardContent>
          <CardContent style={{borderTop:'5px solid var(--f7-theme-color)'}}>
            <List inset>
                <ListItem title={<>Totalt<br/><span style={{fontSize:'13px',color:'#666'}}>(Totalsum inkludert {this.formatVat(this.state.order.price.total)} mva)</span></>}  after={<b style={{color:'#000'}}>{this.formatPrice(this.state.order.price.total)}</b>}/>
            </List>
          </CardContent>
        </Card>
      </>
    )
  }
  
  sumDays(time1,time2){
    var diff = ((time2 - time1)/86400) - 1;

    if(diff < 0){
      diff = 0;
    }
    return Math.round(diff);
 }

  totalPeriodePrice() {

    var sumDays = this.sumDays(this.state.order.periode.start,this.state.order.periode.end);

    var sumPrice = null;
    if(sumDays){
      sumPrice = this.state.order.price.start + this.state.order.price.day*sumDays;
    }else{
      sumPrice = this.state.order.price.start;
    }

    return <>kr {sumPrice.toLocaleString().replace(/,/g," ",)},-</>;

  }

  formatPrice(price) {
    return <>kr {price.toLocaleString().replace(/,/g," ",)},-</>;
  }

  formatVat(price){
    
    price = (price - Math.round(price/1.25));

    return <>kr {price.toLocaleString().replace(/,/g," ",)},-</>;
      
  }

  renderPayment(){
    return <Card>
      <CardContent>
        <List inset>
          {this.state.order.payment_methode === 1 && (
            <ListItem title={<>Betales med faktura til:</>} after={this.state.order.payment.name}/>
          )}
          {this.state.order.payment_methode === 2 && (
            <ListItem title={<>Betalt med</>} after={<>{this.state.order.payment.brand.toUpperCase()} {this.state.order.payment.last4}</>}/>
          )}
          {this.state.order.payment_methode === 3 && (
            <ListItem title={<>Betalt med</>} after={<>Vipps</>}/>
          )}
          <ListItem title={<>Ordre-ID</>} after={this.formatBID()}/>
        </List>
      </CardContent>
    </Card>;
  }

  render() {

    return (
  <Page onPageInit={this.onPageInit.bind(this)} >
    <PlNavbar/>

    <div style={{width:'100%',maxWidth:'740px',marginLeft:'auto',marginRight:'auto'}}>
      
      {this.state.loading
          ? (
            <>
            <BlockTitle className="text-align-center">Vent litt</BlockTitle>
            <Block className="text-align-center">
              <Preloader></Preloader>
            </Block>
          </>
            )
          : (
            <>

      {this.state.error
          ? (
            <>
              <BlockTitle className="text-align-center">En feil har oppstått</BlockTitle>
              <Block className="text-align-center">
                <p>Skjekk om du har skrevet inn rett adresse.</p>
                <p>Du kan også prøve å laste inn siden på nytt.</p>
              </Block>
              <div style={{width:'100%',maxWidth:'240px',marginLeft:'auto',marginRight:'auto'}}>
                <Button onClick={()=>{window.location.reload()}} large outline>Last inn på nytt</Button>
              </div>
              <br/>
              <Block className="text-align-center">
                <p><b>Har du fremdeles problemer?<br/>Ta kontakt, så hjelper vi deg.</b></p>
              </Block>
              <div style={{width:'100%',maxWidth:'240px',marginLeft:'auto',marginRight:'auto'}}>
              <Button href={'/kontakt?ref='+this.state.order.bID} raised fill large color="pink">Ta kontakt</Button>
              </div>
            </>
            )
          : (
            <>

            {this.state.order.paid === 0 && this.state.order.payment_methode !== 1
            ? (
              <>
              <Payment reload={this.loadXhr.bind(this)} price={this.state.order.price} tel={this.state.order.tel} sid={this.$f7route.params.sid}/>
              </>
            ):(
              <>

              {this.renderFeedbackCard()}

              {this.state.order.paid === 1 && this.state.order.payment_methode === 1 &&
                this.renderInvoiceDownload()
              }

              {this.state.order.paid === 0 && this.state.order.payment_methode === 1 &&
                this.renderInvoiceWaitingReview()
              }

              {this.state.order.methode === 1 &&
              (
                this.renderMethode1()
              )}
              {this.state.order.methode === 2 &&
              (
                this.renderMethode2()
              )}
              {this.order()}
              {this.renderTotalPrice()}
              {this.renderPayment()}
              </>
            )}

      </>
      )}

    </>
    )}
    <Block className="text-align-center">
      PARTYLYD AS | Org.nr. 919 580 127 MVA | kontakt@partylyd.no
    </Block>
    </div>
  </Page>);
  }

  onPageInit() {
    this.loadXhr();
  }
  
  loadXhr() {
 
    const sid = this.$f7route.params.sid;

    this.setState({
      loading: true,
    });

    fetch(API_URL+'/api/order/get', {
      method: 'POST',
      body: JSON.stringify({
        sid: sid
      }),
      headers : new Headers(),
    })
    .then(res => res.json())
    .then(
      (result) => {

        if(result.err_code === 0){
          this.setState({
            loading: false,
            error: false,
            order: result
          });

          this.setPackageLocaleStorage();

        }else{
          this.setState({
            loading: false,
            error: true
          });
        }
      },
      (error) => {
        this.setState({
          loading: false,
          error: true
        });
      }
    )
  }
  setPackageLocaleStorage(){

    localStorage.setItem('order', '{"sURL":"'+this.state.order.sURL+'","name":"'+this.state.order.name+'","start":'+this.state.order.periode.start+',"end":'+this.state.order.periode.end+'}');

  }
};