import * as React from 'react';
import {
    Card,
    CardContent,
    CardFooter,
    BlockTitle,
    Row,
    Col,
} from 'framework7-react';

import './payment.css'

import {API_URL} from '../../../api';

import VippsCheckout from './vipps/VippsCheckout';
import StripeCheckout from './stripe/StripeCheckout';
import InvoiceCheckout from './invoice/InvoiceCheckout';

export default class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          vipps: true,
          card: false,
          invoice: false,
        }
      }

    render() {
      return <>
            <BlockTitle medium>Velg betalingsmetode</BlockTitle>
            <Row noGap>
                <Col onClick={() => this.setState({vipps: true, card: false, invoice: false})} width="50" medium="33">
                    <Card className={this.state.vipps ? ('card-selected'):('')}>
                        <CardContent>
                            <div className="card-inner-img-vipps"></div>
                        </CardContent>
                        <CardFooter>
                            <p>Vipps</p>
                        </CardFooter>
                    </Card>
                </Col>
                <Col onClick={() => this.setState({vipps: false, card: true, invoice: false})} width="50" medium="33">
                    <Card className={this.state.card ? ('card-selected'):('')}>
                        <CardContent>
                            <div className="card-inner-img-card"></div>
                        </CardContent>
                        <CardFooter>
                            <p>Betalingskort</p>
                        </CardFooter>
                    </Card>
                </Col>
                <Col onClick={() => this.setState({vipps: false, card: false, invoice: true})} width="50" medium="33">
                    <Card className={this.state.invoice ? ('card-selected'):('')}>
                        <CardContent>
                            <div className="card-inner-img-invoice"></div>
                        </CardContent>
                        <CardFooter>
                            <p>Faktura <span style={{fontSize:'12px',whiteSpace:'nowrap'}}>(+ 45 kr)</span></p>
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            
            {this.state.vipps &&
                <VippsCheckout sid={this.props.sid} price={this.props.price.total} tel={this.props.tel}/>
            }

            {this.state.card &&
                <StripeCheckout sid={this.props.sid} price={this.props.price.total} reload={this.props.reload.bind(this)}/>
            }
            
            {this.state.invoice &&
                <InvoiceCheckout sid={this.props.sid} price={this.props.price.total} tel={this.props.tel} reload={this.props.reload.bind(this)}/>
            }
      </>;
    }

    pay_demoXhr() {
        var self = this;
        const app = this.$f7;
        const sid = this.props.sid;
  
        app.dialog.preloader('Vent litt');
    
        fetch(API_URL+'/api/order/pay_demo', {
          method: 'POST',
          body: JSON.stringify({
            sid: sid
          }),
          headers : new Headers(),
        })
        .then(res => res.json())
        .then(
          () => {
            app.dialog.close();
            self.props.reload();
          }
        )
    }
};