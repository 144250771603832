import React from 'react';

import {StripeProvider, Elements} from 'react-stripe-elements';
import InjectedCheckoutForm from './CheckoutForm';

export default class StripeCheckout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {stripe: null};
    }

    render() {
        return (
            <StripeProvider stripe={this.state.stripe}>
                <Elements locale="no">
                    <InjectedCheckoutForm sid={this.props.sid} price={this.props.price} reload={this.props.reload.bind(this)}/>
                </Elements>
            </StripeProvider>
        );
    }

    componentDidMount() {
        //load stripe
        this.loadStripeScript();
        
        if (window.Stripe) {
            this.setState({stripe: window.Stripe('pk_live_8l3d9BrD9cvBP2UHwTyEhstm00uInl61cR')});
          } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
              // Create Stripe instance once Stripe.js loads
              this.setState({stripe: window.Stripe('pk_live_8l3d9BrD9cvBP2UHwTyEhstm00uInl61cR')});
            });
        }
    }

    loadStripeScript() {
        var aScript = document.createElement('script');
        aScript.setAttribute("id", "stripe-js");
        aScript.type = 'text/javascript';
        aScript.src = "https://js.stripe.com/v3/";
        document.head.appendChild(aScript);
        aScript.onload = function() {
            console.log('ready');
        };
    }
}