import NotFoundPage from './components/pages/NotFoundPage';

import OrderPage from './components/pages/OrderPage/main';

import PackagePage from './components/pages/PackagePage/main';

import HomePage from './components/pages/HomePage/main';

import LeaseTerms from './components/pages/LeaseTermsPage/main';

import ContactPage from './components/pages/ContactPage';

import AboutPage from './components/pages/AboutPage';

import OurLocationsPage from './components/pages/ourLocationsPage/main';

import PackagesPage from './components/pages/PackagesPage/main';

import FindPackagePage from './components/pages/FindPackagePage/main';

import FeedbackPage from './components/pages/feedbackPage/main';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-145053818-1');

export default [
  //store
  {
    path: '/',
    component: HomePage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/');
      }
    }
  },
  {
    path: '/leiebetingelser',
    component: LeaseTerms,
    on: {
      pageInit: function () {
        ReactGA.pageview('/leiebetingelser');
      }
    }
  },
  {
    path: '/kontakt',
    component: ContactPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/kontakt');
      }
    }
  },
  {
    path: '/om-oss',
    component: AboutPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/om-oss');
      }
    }
  },
  {
    path: '/hvor-finner-du-oss',
    component: OurLocationsPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/hvor-finner-du-oss');
      }
    }
  },
  {
    path: '/pakker',
    component: PackagesPage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/pakker');
      }
    }
  },
  {
    path: '/finn-riktig-pakke',
    component: FindPackagePage,
    on: {
      pageInit: function () {
        ReactGA.pageview('/finn-riktig-pakke');
      }
    }
  },
  {
    path: '/pakke/:id',
    component: PackagePage,
    on: {
      pageInit: function () {
        ReactGA.pageview(ReactGA.pageview(window.location.pathname + window.location.search));
      }
    }
  },
  //order
  {
    path: '/order/:sid',
    component: OrderPage,
    on: {
      pageInit: function () {
        ReactGA.pageview(ReactGA.pageview(window.location.pathname + window.location.search));
      }
    },
    routes: [
      {
        path: 'feedback',
        component: FeedbackPage
      },
    ]
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];
