import * as React from 'react';
import {
    Card,
    CardContent,
    Block,
    BlockTitle,
    List,
    ListItem,
    Button,
    Icon,
} from 'framework7-react';

export default class addExtraItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loaded:false,
          failed:false
        }
      }
    
    bgImgStyle(path){
        return 'url('+path+')';
    }

    sumDays(time1,time2){
        var diff = ((time2 - time1)/86400000) - 1;
   
        if(diff < 0){
          diff = 0;
        }
        return Math.round(diff);
     }

    renderItemPrice(price){
        var sumDays = this.sumDays(this.props.order.periode.start,this.props.order.periode.end);
        var sumPrice = null;
        if(sumDays){
          sumPrice = price.start + price.day*sumDays;
        }else{
          sumPrice = price.start;
        }

        if(this.props.order.periode.start){
            return <><b>kr. {sumPrice.toLocaleString().replace(/,/g," ",)},-</b></>;
        }else{
            return <>fra <b>kr. {sumPrice.toLocaleString().replace(/,/g," ",)},-</b></>;
        }

    }

    filterInPlace(array, predicate) {
        let end = 0;
    
        for (let i = 0; i < array.length; i++) {
            const obj = array[i];
    
            if (predicate(obj)) {
                array[end++] = obj;
            }
        }
    
        array.length = end;
    };

    itemsArray(){
        let optionalItems = this.props.optionalItems;
        let items = this.props.items;
        let arr = []
        let result = []
        
        for (var i = 0; i < optionalItems.length; i++) {
            
            arr.push(optionalItems[i]['id']);
            
        }
        for (var i = 0; i < items.length; i++) {
            
            if(arr.indexOf(items[i]['id']) <= -1){
                result.push(items[i])
            }
            
        }
        return result;
    }

    render() {
      return <>
            {this.itemsArray().length > 0 &&
            <>
            <BlockTitle medium>Legg til tilleggsprodukter</BlockTitle>
            <Card>
                <CardContent>
                    <List mediaList>
                    {this.itemsArray().map(n => (
                        <ListItem
                        key={n.id}
                        title={n.name}
                        subtitle={<>+ {this.renderItemPrice(n.price)}</>}
                        text={<span dangerouslySetInnerHTML={{ __html: n.desc}}></span>}
                        >
                        <Block style={{ backgroundSize: 'auto 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '70px', height: '70px', borderRadius: '10%',  backgroundImage: this.bgImgStyle(n.img) }} slot="media"></Block>
                        <Button onClick={()=>this.props.addExtraItem(n)} slot="after" small fill><Icon material="add" size="26px" style={{marginLeft:'-8px',marginTop:'-2px',marginRight:'2px'}}></Icon>Legg til</Button>
                        </ListItem>
                    ))}
                    </List>
                </CardContent>
            </Card>
            </>
            }
      </>;
    }

};