import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Page,
    Link,
    Row,
    Col,
} from 'framework7-react';

import {API_URL} from '../../../api';

import PlNavbar  from '../../shared/navbar/navbar';
import PlBottomBar  from '../../shared/bottomBar/bottomBar';


import ExistingRent from './existingRent';

import HomeSwiper from './homeSwiper';
import OurLocations from './ourLocations';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      featured_packages: []
    }
  }
  relativeUrl(path){
    return this.$f7route.url + path;
  }
  bgImgStyle(path){
    return 'url('+path+')';
  }
  render() {
    return (
  <Page>
    <PlNavbar/>
    
    <ExistingRent/>
    
    <HomeSwiper/>

    <h3>Noen av våre pakker</h3>
    {this.state.loading
          ? (
          <>
            <Row noGap style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto'}}>
              {[1,2,3,4,5,6].map(n => (
                <Col key={n} width="100" medium="50" large="33">
                  <Card className="skeleton-text skeleton-effect-pulse">
                    <CardHeader className="no-border" style={{ height: '240px', backgroundColor: '#ccc'}}/>
                    <CardContent>
                      <h2>Lorem ibsum</h2>
                      <h4>Quisque eget vestibulum nulla.</h4>
                      <p>Quisque eget vestibulum nulla. Quisque quis dui quis ex ultricies efficitur vitae non felis. Phasellus quis nibh hendrerit...</p>
                    </CardContent>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
            )
          : (
          <>
            <Row noGap style={{width:'100%', maxWidth:'1040px',marginLeft:'auto',marginRight:'auto',marginBottom:'26px'}}>
              {this.state.featured_packages.map(n => (
                <Col key={n.id} width="100" medium="50" large="33">
                  <Link href={"/pakke/"+n.id} style={{width:'100%'}}>
                    <Card className="package-home-card elevation-6 elevation-hover-12 elevation-pressed-24 elevation-transition">
                      <CardHeader
                        className="no-border"
                        valign="bottom"
                        style={{ backgroundImage: this.bgImgStyle(n.img) }}
                      />
                      <CardContent>
                        <h2>
                        {n.name}
                        </h2>
                        <h4>
                        Lei fra <b>kr. {n.price},-</b>
                        </h4>
                        <p>{n.desc}</p>
                      </CardContent>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </> 
    )
  }


    <div className="ourLocationsDiv" style={{backgroundColor:'#e67e22'}}>
      <div style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto'}}>
        <h3>Finner du ikke pakken du leter etter?</h3>

        <Row noGap>
            <Col width="100" medium="50">
              <Link href={"/finn-riktig-pakke"} style={{width:'100%'}}>
                <Card className="package-home-card elevation-6 elevation-hover-12 elevation-pressed-24 elevation-transition">
                    <CardContent>
                      <h2>Finn riktig pakke</h2>
                      
                      <Row noGap>
                      <Col width="90">
                        <p>Trykk <b>her</b> for å få hjelp til å finne en pakke som passer perfekt til ditt arrangement!</p>
                      </Col>
                      <Col width="10">  
                        <span style={{float:'right', marginLeft:'6px'}}>
                            <i className="material-icons">arrow_forward</i>
                        </span>
                      </Col>
                      </Row>
                    </CardContent>
                </Card>
              </Link>
            </Col>
            <Col width="100" medium="50">
              <Link href={"/pakker"} style={{width:'100%'}}>
                <Card className="package-home-card elevation-6 elevation-hover-12 elevation-pressed-24 elevation-transition">
                    <CardContent>
                      <h2>Se alle pakkene</h2>

                      <Row noGap>
                      <Col width="90">
                      <p>Trykk <b>her</b> for å se alle de ulike pakketilbudene og kategoriene vi har tilgjengelig!</p>
                      </Col>
                      <Col width="10">  
                        <span style={{float:'right', marginLeft:'6px'}}>
                            <i className="material-icons">arrow_forward</i>
                        </span>
                      </Col>
                      </Row>
                    </CardContent>
                </Card>
              </Link>
            </Col>
        </Row>
      </div>
    </div>

    <OurLocations/>

    <PlBottomBar/>

  </Page>);
  }

  componentDidMount(){
    this.loadXhr();
  }

  loadXhr() {

    fetch(API_URL+'/api/store/ini')
    .then(response => {
      return response.json()
    })
    .then(data => {
      
      this.setState({
        loading:false,
        error:false,
        featured_packages:data.featured_packages
      });

    })
    .catch(err => {
      this.setState({
        loading:false,
        error:true,
      });
    })

}
};