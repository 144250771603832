import React from 'react';
import {
    Card,
    CardContent,
    Block,
    BlockTitle,
    List,
    Row,
    Col,
    Button,
    ListInput
} from 'framework7-react';

import {API_URL} from '../../../../api';

export default class InvoiceCheckout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //name
            errClassName: '',
            name: null,
            //nr
            errClassNr: '',
            nr: null,
            //adr
            errClassAdr: '',
            adr: null,
            //zip
            errClassZip: '',
            zip: null,
            //email
            errClassEmail: '',
            email: null,
            //tel
            errClassTel: '',
            tel: this.props.tel,
            //error
            errMgs: null,
            formError: false,
        };
    }

    sumInvoice(){
        return parseInt(this.props.price) + 45;
    }

    calcVAT(){
        let sum = parseInt(this.props.price) + 45;
        return (sum - Math.round(sum/1.25));
    }

    //Update name
    updateName(e){
        let name = e.target.value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()});
        this.setState({
          errClassName: '',
          name: name
        });
    }

    //Update nr
    updateNr(e){
        this.setState({
          errClassName: '',
          nr: e.target.value
        });
    }
    blurNr(e){
        let nr = e.target.value.replace(/\D/g,'');
        let errClassNr = '';
        let formError = false;

        if(nr.length === 6){
            let y = 19;
            if(nr.substring(4, 6) < 30){
                y = 20;
            }
            nr = nr.substring(0, 2) + '-' + nr.substring(2, 4) + '-' + y + nr.substring(4, 6);
        }else if(nr.length === 8){
            nr = nr.substring(0, 2) + '-' + nr.substring(2, 4) + '-' + nr.substring(4, 8);
        }else if(nr.length === 9){
            nr = nr.substring(0, 3) + ' ' + nr.substring(3, 6) + ' ' + nr.substring(6, 9);
        }else{
            errClassNr = 'cardErrorShaddow';
            formError = true;
        }

        this.setState({
            errClassNr: errClassNr,
            nr: nr,
            formError: formError
        });

    }

    //Update adr
    updateAdr(e){
        this.setState({
          errClassAdr: '',
          adr: e.target.value
        });
    }

    //Update zip
    updateZip(e){
        this.setState({
          errClassZip: '',
          zip: e.target.value
        });
    }

    //Update email
    updateEmail(e){
        this.setState({
          errClassEmail: '',
          email: e.target.value
        });
    }

    //Update zip
    updateTel(e){
        this.setState({
          errClassTel: '',
          tel: e.target.value
        });
    }

    render() {
        return (
            <>
            <BlockTitle medium>Faktura</BlockTitle>
            <Block>
                <p>Faktura sendes på e-post, og forfaller <b>10 dager</b> etter første lånedato. Et fakturagebyr på NOK 45,- kommer i tillegg. Totalbeløp blir <b>NOK {this.sumInvoice()},-</b> hvorav mva. {this.calcVAT()},-</p>
            </Block>

            {this.state.errMgs &&
            <Block className="text-color-red">
                <p>
                    {this.state.errMgs}
                </p>
            </Block>
            }

            <BlockTitle>Personalia</BlockTitle>
            <Row noGap>
                <Col width="100" medium="50">
                    <Card className={`${this.state.errClassName}`}>
                        <CardContent>
                            <List>
                            <ListInput
                                label="Navn på fakturamottaker"
                                type="text"
                                placeholder="Skriv inn navn"
                                value={this.state.name}
                                onChange={(e) => this.updateName(e)}
                            />
                            </List>
                        </CardContent>
                    </Card>
                </Col>
                <Col width="100" medium="50">
                    <Card className={`${this.state.errClassNr}`}>
                        <CardContent>
                            <List>
                            <ListInput
                                label="Fødselsdato (Org.nr. for bedrift)"
                                type="text"
                                placeholder="dd-mm-yyyy (eks. 01-01-1996)"
                                errorMessage="Skriv inn med tall"
                                validate
                                pattern="[0-9 _-]*"
                                value={this.state.nr}
                                onChange={(e) => this.updateNr(e)}
                                onBlur={(e) => this.blurNr(e)}
                            />
                            </List>
                        </CardContent>
                    </Card>
                </Col>
                <Col width="100" medium="50">
                    <Card className={`${this.state.errClassAdr}`}>
                        <CardContent>
                            <List>
                            <ListInput
                                label="Adresse"
                                type="text"
                                placeholder="Skriv inn adresse"
                                value={this.state.adr}
                                onChange={(e) => this.updateAdr(e)}
                            />
                            </List>
                        </CardContent>
                    </Card>
                </Col>
                <Col width="100" medium="50">
                    <Card className={`${this.state.errClassZip}`}>
                        <CardContent>
                            <List>
                            <ListInput
                                label="Postnummer"
                                type="tel"
                                errorMessage="Skriv inn med tall"
                                maxlength="4"
                                validate
                                pattern="[0-9]*"
                                placeholder="xxxx"
                                value={this.state.zip}
                                onChange={(e) => this.updateZip(e)}
                            />
                            </List>
                        </CardContent>
                    </Card>
                </Col>
                <BlockTitle>Kontaktinformasjon</BlockTitle>
            </Row>
            <Row>
                <Col width="100" medium="50">
                    <Card className={`${this.state.errClassEmail}`}>
                        <CardContent>
                            <List>
                            <ListInput
                                label="E-postadresse"
                                type="email"
                                placeholder="Skriv inn e-postadresse"
                                value={this.state.email}
                                onChange={(e) => this.updateEmail(e)}
                            />
                            </List>
                        </CardContent>
                    </Card>
                </Col>
                <Col width="100" medium="50">
                    <Card className={`${this.state.errClassTel}`}>
                        <CardContent>
                            <List>
                            <ListInput
                                label="Mobilnummer"
                                type="tel"
                                placeholder="xxx xx xxx"
                                value={this.state.tel}
                                onChange={(e) => this.updateTel(e)}
                            />
                            </List>
                        </CardContent>
                    </Card>
                </Col>
            </Row>
            <Block>
                <Button onClick={() => this.checkForm()} large raised fill>Send faktura</Button>
            </Block>
            </>
        );
    }

    checkForm(){
        const self = this;
        
        self.setState({
            formError: false
        });

        if(!this.state.name){
            self.setState({
              errClassName: 'cardErrorShaddow',
              formError: true
            });
        }

        if(!this.state.nr){
            self.setState({
              errClassNr: 'cardErrorShaddow',
              formError: true
            });
        }

        if(!this.state.adr){
            self.setState({
              errClassAdr: 'cardErrorShaddow',
              formError: true
            });
        }

        if(!this.state.zip){
            self.setState({
              errClassZip: 'cardErrorShaddow',
              formError: true
            });
        }

        if(!this.state.email){
            self.setState({
              errClassEmail: 'cardErrorShaddow',
              formError: true
            });
        }

        if(!this.state.tel){
            self.setState({
              errClassTel: 'cardErrorShaddow',
              formError: true
            });
        }

        if(this.state.formError){
            self.setState({
                errMgs: 'Du har ikke fylt ut alle feltene korrekt. Fyll ut feltene, og prøv på nytt.'
            });
        }else{
            self.setState({
                errMgs: null
            });
            self.invoiceXhr();
        }
          
    }

    invoiceXhr() {
        const self = this;
        const app = this.$f7;
        const sid = this.props.sid;
        
        app.dialog.preloader('Vent litt');

        fetch(API_URL+'/api/payment/invoice', {
          method: 'POST',
          body: JSON.stringify({
            sid: sid,
            invoice: {
                name: this.state.name,
                nr: this.state.nr,
                adr: this.state.adr,
                zip: this.state.zip,
                email: this.state.email,
                tel: this.state.tel,
            }
          }),
          headers : new Headers(),
        })
        .then(res => res.json())
        .then(
          (result) => {
            app.dialog.close();
            if(result.err_code === 0){
              self.props.reload();
            }else{
              this.setState({
                errMgs: result.err_mgs
              });
            }
          },
          (error) => {
            app.dialog.close();
            this.setState({
              errMgs: 'En ukjent feil har oppstått, prøv igjen senere.'
            });
          }
        )
      }

}