import * as React from 'react';
import {
    Navbar,
    NavLeft,
    NavTitle,
    NavRight,
    Link,
    Button
} from 'framework7-react';

import './navbar.css'

export default class PlNavbar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          card: true,
        }
      }

    render() {
      return <>
        <Navbar>
          <Link href="/" style={{marginLeft:'-12px'}}>
            <NavLeft>
                <i className="logo-pl"></i>
            </NavLeft>
            <NavTitle title={'PartyLyd.no'} subtitle={'Lyd og lysutleie'}/>
          </Link>
            <NavRight>
                <Link href="/pakker" className="hideMobileScreen">Våre pakker</Link>
                <Link href="/om-oss" className="hideMobileScreen">Om oss</Link>
                <Link href="/hvor-finner-du-oss" className="hideMobileScreen">Hvor finner du oss</Link>
                <Link href="/leiebetingelser" className="hideMobileScreen">Leiebetingelser</Link>
                <Button href="/kontakt" raised fill color="pink">Ta kontakt</Button>
                <Link panelOpen="right" className="NavbarIconView hideFullScreen" iconMaterial="menu" iconOnly={true}></Link>
            </NavRight>
        </Navbar>
      </>;
    }
};