import * as React from 'react';
import {
  Card,
  CardContent,
  Row,
  Col,
  Icon,
} from 'framework7-react';

import './existingRent.css'

export default class ExistingRent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          exist: false,
          order: {}
        }
      }

    goToOrder() {
      const app = this.$f7;
      app.views.main.router.navigate('/order/'+this.state.order.sURL)
    }

    timeConverter(UNIX_timestamp){
      var a = new Date(UNIX_timestamp * 1000);
      var months = ['januar','februar','mars','april','mai','juni','juli','august','september','oktober','november','desember'];
      var days = ['søndag','mandag','tirsdag','onsdag','torsdag','fredag','lørdag'];
      var day = days[a.getDay()];
      var month = months[a.getMonth()];
      var date = "0" + a.getDate();
      var time = day + ', ' + date.substr(-2) + '. ' + month;
      return time;
    }

    currentTime() {
      return Date.now()/1000;
    }

    componentDidMount() {

      let order = JSON.parse( localStorage.getItem( 'order' ) );

      if(order){
        this.setState({exist: true, order : order });
      }else{
        this.setState({exist: false});
      }
    }

    render() {
      return <>
      {this.state.exist &&
        <>
        {this.state.order.end > this.currentTime() &&
        <div className="existingRentDiv">
        <div style={{width:'100%',maxWidth:'573px',marginLeft:'auto',marginRight:'auto'}}>
          <Card className="package-home-card elevation-6 elevation-hover-12 elevation-pressed-24 elevation-transition">
              <div onClick={()=>this.goToOrder()}>
              <CardContent>
                <h2>Gå til aktiv bestilling</h2>
                
                <Row noGap>
                <Col width="90">
                  <p>
                    Du har en aktiv bestilling av <b>{this.state.order.name}</b> fra <b>{this.timeConverter(this.state.order.start)}</b> til <b>{this.timeConverter(this.state.order.end)}</b>.
                  </p>
                  <p>Trykk <b>her</b> for å gå til bestillingen.</p>
                </Col>
                <Col width="10">
                  <span style={{float:'right', marginLeft:'6px'}}>
                      <Icon material="arrow_forward"/>
                  </span>
                </Col>
                </Row>
              </CardContent>
              </div>
          </Card>
        </div>
        </div>
        }
        </>
      }
      </>;
    }
};