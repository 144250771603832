import * as React from 'react';
import {
    Card,
    CardContent,
    Block,
    BlockTitle,
    List,
    ListItem,
    Button,
    Icon,
} from 'framework7-react';

export default class ChooseItemGroupe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded:false,
            items:null,
        }
      }
    
    bgImgStyle(path){
        return 'url('+path+')';
    }

    filterInPlace(array, predicate) {
        let end = 0;
    
        for (let i = 0; i < array.length; i++) {
            const obj = array[i];
    
            if (predicate(obj)) {
                array[end++] = obj;
            }
        }
    
        array.length = end;
    };

    itemsArray(){
        let optionalItems = this.props.optionalItems;
        let items = this.props.items;
        let arr = []
        let result = []
        
        for (var i = 0; i < optionalItems.length; i++) {
            
            arr.push(optionalItems[i]['id']);
            
        }

        for (var i = 0; i < items.length; i++) {
            
            if(arr.indexOf(items[i]['id']) <= -1){
                result.push(items[i])
            }
            
        }
        return result;
    }

    render() {
        return <>
              {this.itemsArray().length > 0 &&
              <>
              <BlockTitle id={'chooseLight'} medium>Velg tre valgfrie LED-lamper</BlockTitle>
              <Block>
                <p>Du må velge <b>3</b> valgfrie LED-lamper til din bestilling. Du velger LED-lampe ved å trykke på <i>Velg lampe</i> knappen på lampene du ønsker.</p>
              </Block>
              <Card style={{marginTop:'-10px'}}>
                  <CardContent>
                      <List mediaList>
                      {this.itemsArray().map(n => (
                          <ListItem
                          key={n.id}
                          title={n.name}
                          text={<span dangerouslySetInnerHTML={{ __html: n.desc}}></span>}
                          >
                          <Block style={{ backgroundSize: 'auto 100%', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', width: '70px', height: '70px', borderRadius: '10%',  backgroundImage: this.bgImgStyle(n.img) }} slot="media"></Block>
                          <Button onClick={()=>this.props.chooseLightItem(n)} slot="after" small fill><Icon material="add" size="26px" style={{marginLeft:'-8px',marginTop:'-2px',marginRight:'2px'}}></Icon>Velg lampe</Button>
                          </ListItem>
                      ))}
                      </List>
                  </CardContent>
              </Card>
              </>
              }
        </>;
      }

};