import * as React from 'react';
import {
    Block
} from 'framework7-react';

export default class PlBottomBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          card: true,
        }
      }

    render() {
      return <Block className="text-align-center" style={{width:'100%',maxWidth:'700px',marginLeft:'auto',marginRight:'auto'}}>
       <p>PARTYLYD AS | Org.Nr. 911 978 563  MVA | kontakt@partylyd.no</p>
       {/*
       <p style={{fontSize:'12px'}}>Partylyd.no sin nettbutikk, og systemet for deres selvbetjente lagere er utviklet og driftet av <a href="https://iwd.no" className="external" target="_blank">INTELLIGENT WEB DESIGN AS</a>. Vi spesiallager nettløsninger for små og store bedrifter. Besøk oss på <b><a href="https://iwd.no" className="external" target="_blank">www.iwd.no</a></b>.</p>
       */}
      </Block>
    }
};