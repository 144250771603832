import React from 'react';
import {
  App,
  Panel,
  View,
  List,
  BlockTitle,
  ListItem,
  Button,
  Block
} from 'framework7-react';

import routes from '../routes';

export default function (props) {

  const f7params = {
    id: 'no.partylyd.app',
    name: 'Partylyd.no',
    theme: 'md',
    animate: false,
    stackPages: true,
    // App routes
    routes,
    view: {
      pushState: true,
      pushStateSeparator:"",
      animate: false
    },
  };

  return (
    <App params={f7params}>
      <Panel right>
        <BlockTitle medium>Meny</BlockTitle>
        <List>
          <ListItem panelClose title="Forsiden" link="/"></ListItem>
          <ListItem panelClose title="Se alle pakker" link="/pakker"></ListItem>
          <ListItem panelClose title="Finn riktig pakke" link="/finn-riktig-pakke"></ListItem>
          <ListItem panelClose title="Hvor finner du oss" link="/hvor-finner-du-oss"></ListItem>
          <ListItem panelClose title="Om oss" link="/om-oss"></ListItem>
          <ListItem panelClose title="Leiebetingelser" link="/leiebetingelser"></ListItem>
          <Block>
            <Button panelClose href="/kontakt" raised fill color="pink">Ta kontakt</Button>
          </Block>
        </List>
      </Panel>
      <View id="main-view" url="/" main className="safe-areas"/>
    </App>
  );
};
