// CheckoutForm.js
import React from 'react';
import {injectStripe, CardElement} from 'react-stripe-elements';

import {
  Card,
  CardContent,
  Block,
  BlockTitle,
  Button,
} from 'framework7-react';

import {API_URL} from '../../../../api';

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cardElement: null,
      errClassCardElement: '',
      errMgs: false
    };
  }
  
  handleSubmit() {
    var self = this;
    const app = this.$f7;

    let cardElement = this.state.cardElement;

    if(cardElement === null || cardElement.empty){
      self.setState({
        errClassCardElement: 'cardErrorShaddow',
        errMgs: 'Fyll ut ditt betalingskort i feltet.'
      });
      return false;
    }

    if(cardElement.error){
      let errorMgs = cardElement.error.message;
      self.setState({
        errClassCardElement: 'cardErrorShaddow',
        errMgs: errorMgs
      });
      return false;
    }

    if(cardElement.complete === false){
      let errorMgs = 'Fyll ut alle feltene.';
      self.setState({
        errClassCardElement: 'cardErrorShaddow',
        errMgs: errorMgs
      });
      return false;
    }

    app.dialog.preloader('Fullfører betaling');

   this.props.stripe
      .createPaymentMethod('card')
      .then((result) => {
        if (result.error) {
          
          app.dialog.close();
          
          this.setState({
            errClassCardElement: 'cardErrorShaddow',
            errMgs: 'Ditt betalingskort har blitt avvist. Prøv en annen betalingsmetode.'
          });

        } else {
          self.payXhr(result.paymentMethod.id, false);
        }
    });

  };

  payXhr(token,SCA) {
    const self = this;
    const app = this.$f7;
    const sid = this.props.sid;
    ///api/payment/stripe
    fetch(API_URL+'/api/payment/stripe', {
      method: 'POST',
      body: JSON.stringify({
        sid: sid,
        tok: token,
        SCA: SCA
      }),
      headers : new Headers(),
    })
    .then(res => res.json())
    .then(
      (result) => {
        if(result.err_code === 0){
          app.dialog.close();
          self.props.reload();
        }else if(result.err_code === 1){
          self.pay3dSecure(result.tok);
        }else if(result.err_code === 2){
          app.dialog.close();
          this.setState({
            errClassCardElement: 'cardErrorShaddow',
            errMgs: result.err_mgs
          });
        }else{
          this.setState({
            errMgs: result.err_mgs
          });
        }
      },
      (error) => {
        app.dialog.close();
        this.setState({
          errClassCardElement: 'cardErrorShaddow',
          errMgs: 'En ukjent feil har oppstått, prøv igjen senere.'
        });
      }
    )
  }

  pay3dSecure(tok){
    const self = this;
    const app = this.$f7;
    this.props.stripe.handleCardAction(
      tok
    ).then(function(result) {
      if (result.error) {
        app.dialog.close();
        self.setState({
          errClassCardElement: 'cardErrorShaddow',
          errMgs: 'En ukjent feil har oppstått, prøv igjen senere.'
        });
      } else {
        self.payXhr(result.paymentIntent.id, true);
      }
    });

  }

  render() {
    return (
      <>
        <BlockTitle medium>Betalingskort</BlockTitle>
        {this.state.errMgs &&
        <>
        <Block className="text-color-red" style={{marginBottom:0}}>
          <p>
            {this.state.errMgs}
          </p>
        </Block>
        </>
        }
        <Card style={{minHeight:'54px'}} className={`${this.state.errClassCardElement}`}>
          <CardContent>
            <CardElement onChange={(e)=>this.setState({cardElement: e, errClassCardElement: '', errMgs: false})} style={{base: {fontSize: '18px'}}} />
          </CardContent>
        </Card>
        <Block>
            <Button onClick={()=>this.handleSubmit()} large raised fill>Betal Kr. {this.props.price},-</Button>
        </Block>
        </>
    );
  }
}

export default injectStripe(CheckoutForm);