import React from 'react';
import {
    Card,
    CardContent,
    Block,
    BlockTitle,
    List,
    Row,
    Col,
    Button,
    ListInput
} from 'framework7-react';

import {API_URL} from '../../../../api';

export default class VippsCheckout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //name
            errClassTel: '',
            tel: this.props.tel || '',
        };
    }

    updateTel(e){
        this.setState({
          errClassTel: '',
          errMgs: null,
          tel: e.target.value
        });
    }

    render() {
        return (
            <>
            {this.state.errMgs &&
            <Block className="text-color-red">
                <p>
                    {this.state.errMgs}
                </p>
            </Block>
            }
            <BlockTitle medium>Mobilnummer</BlockTitle>
            <Row noGap>
                <Col width="100">
                    <Card className={`elevation-hover-3 elevation-transition`}>
                        <CardContent>
                            <List>
                            <ListInput
                                label="Mobilnummer"
                                type="tel"
                                placeholder="xxx-xx-xxx"
                                value={this.state.tel}
                                onChange={(e) => this.updateTel(e)}
                            />
                            </List>
                        </CardContent>
                    </Card>
                </Col>
            </Row>
            <Block>
                <Button  onClick={() => this.vippsXhr()} large raised fill>Betal Kr. {this.props.price},-</Button>
            </Block>
            </>
        );
    }

    vippsXhr() {
        const app = this.$f7;
        const sid = this.props.sid;
        
        app.dialog.preloader('Vent litt');

        fetch(API_URL+'/api/payment/vipps/initiate', {
          method: 'POST',
          body: JSON.stringify({
            sid: sid,
            tel: this.state.tel
          }),
          headers : new Headers(),
        })
        .then(res => res.json())
        .then(
          (result) => {
            app.dialog.close();
            if(result.err_code === 0){
                window.location.href = result.vippsUrl;
            }else{
              this.setState({
                errMgs: result.err_mgs
              });
            }
          },
          (error) => {
            app.dialog.close();
            this.setState({
              errMgs: 'En ukjent feil har oppstått, prøv igjen senere.'
            });
          }
        )
        
      }

}