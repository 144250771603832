import * as React from 'react';
import {
  Swiper,
  SwiperSlide
} from 'framework7-react';

import './homeSwiper.css'

export default class HomeSwiper extends React.Component {

    render() {
      return <>
        <Swiper pagination navigation params={{loop:true,autoplay:{delay:8000}}}>
            <SwiperSlide className="slide-1 text-align-center">
                <div style={{maxWidth:'540px',margin:'auto',paddingLeft:'46px',paddingRight:'46px',}}>
                    <h2 style={{marginTop:'40px',justifyContent:'center'}}>Festen starter hos PartyLyd!</h2>
                    <p style={{fontSize:'20px',fontWeight:500,marginTop:'40px'}}>
                    Velkommen til Norges eneste selvbetjente utleier av festutstyr!
                    </p>
                    <p style={{fontSize:'20px'}}>
                    Her kan du leie alt av lyd og lysutstyr med full fleksibilitet!
                    </p>
                </div>
            </SwiperSlide>
            <SwiperSlide className="slide-2 text-align-center">
                <div style={{maxWidth:'540px',margin:'auto',paddingLeft:'46px',paddingRight:'46px',}}>
                    <h3 style={{marginTop:'40px', color:'#fff'}}>Vi kutter unødvendige utgifter!</h3>
                    <p style={{fontSize:'20px'}}>
                    Når du leier festutstyr hos oss, gjør du mye av jobben selv. Du setter opp din egen pakke, og henter den selv på en av våre selvbetjente lagre.
                    </p>
                    <p style={{fontSize:'20px'}}>
                    Sånn kutter vi egne utgifter, og vi er derfor bransjens billigeste utleier av festutstyr!
                    </p>
                </div>
            </SwiperSlide>
            <SwiperSlide className="slide-3 text-align-center">
                <div style={{maxWidth:'540px',margin:'auto',paddingLeft:'46px',paddingRight:'46px',}}>
                    <h3 style={{marginTop:'40px', color:'#fff'}}>Hvordan funker det?</h3>
                    <p style={{fontSize:'20px',marginTop:'-10px'}}>
                    <b>1.</b> Du velger og bestiller pakken du ønsker. Du velger også hvilket lager du vil hente pakken.
                    </p>
                    <p style={{fontSize:'20px',marginTop:'-14px'}}>
                    <b>2.</b> Du henter din pakke på lageret du har valgt.
                    </p>
                    <p style={{fontSize:'20px',marginTop:'-14px'}}>
                    <b>3.</b> Når du har hentet din bestilling, får du instruksjoner for å montere helt på egenhånd.
                    </p>
                </div>
            </SwiperSlide>
        </Swiper>
      </>;
    }
};