import React from 'react';
import {
    Page,
    Block,
    Row,
    Col,
    Card,
    CardHeader,
    CardContent,
    Link,
} from 'framework7-react';

import PlNavbar  from '../../shared/navbar/navbar';
import PlBottomBar  from '../../shared/bottomBar/bottomBar';

import './main.css'

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opt_1: null,
      opt_2: null
    }
  }
  relativeUrl(path){
    return this.$f7route.url + path;
  }

  bgImgStyle(path){
    return 'url('+path+')';
  }

  returnClassNameActiveOpt1(opt){
    if(this.state.opt_1 === opt){
      return 'active opt-img';
    }
    return 'opt-img';
  }

  returnClassNameActiveOpt2(opt){
    if(this.state.opt_2 === opt){
      return 'active opt-img';
    }
    return 'opt-img';
  }


  renderResults(){

    const packagesArr = [
      {
        id:1,
        name:"Ordinær lydpakke",
        desc:"Med PartyLyds Ordinære lydpakke får du et komplett PA-anlegg med alt det du trenger for å spille musikk eller formidle tale til større forsamlinger.",
        price:599,
        img:"https://pl.iwd.no/www/img/p-1.jpg"
      },{
        id:2,
        name:"Kraftig lydpakke",
        desc:"Komplett pakke med h\u00f8yttalere som har kraftg ytelse og god, fyldig bass. Perfekt til \u00e5 spille h\u00f8y musikk.",
        price:699,
        img:"https://pl.iwd.no/www/img/p-2.jpg"
      },{
        id:3,
        name:"Kraftig lydpakke m/ekstra bass",
        desc:"Komplett pakke med h\u00f8yttalere som har kraftg ytelse og god, fyldig bass. Perfekt til \u00e5 spille h\u00f8y musikk.",
        price:899,
        img:"https://pl.iwd.no/www/img/p-3.jpg"
      },{
        id:4,
        name:"Effektpakke",
        desc:"Pakken inneholder tre valgfrie LED-lamper med stativ, en r\u00f8ykmaskin med 1L v\u00e6ske, og n\u00f8dvendige kabler.",
        price:599,
        img:"https://pl.iwd.no/www/img/p-4.jpg"
      },{
        id:5,
        name:"Medium Partypakke",
        desc:"Komplett lydsystem og lyseffekter \u2013 alt du trenger til ditt arrangement. Passer bra for opp til\u00a0100\u00a0personer.",
        price:899,
        img:"https://pl.iwd.no/www/img/p-5.jpg"
      },{
        id:6,
        name:"Stor Partypakke",
        desc:"Komplett lydsystem og lyseffekter \u2013 alt du trenger til ditt arrangement. Passer bra for opp til\u00a0150\u00a0personer.",
        price:1099,
        img:"https://pl.iwd.no/www/img/p-6.jpg"
      },{
        id:7,
        name:"Jumbo Partypakke",
        desc:"Komplett lydsystem og lyseffekter – alt du trenger til ditt arrangement. Passer bra for opp til 200 personer.",
        price:1299,
        "img":"https://pl.iwd.no/www/img/p-7.jpg"
      },{
        id:8,
        name:"Soundboks 2",
        desc:"SOUNDBOKS 2 er en batteridrevet lydtrykkskanon med innebygget Bluetooth. H\u00f8y lyd og lang batterilevetid selvf\u00f8lgelig.",
        price:599,
        "img":"https://pl.iwd.no/www/img/p-8.jpg"
      }
    ];
    let arr = [];

    if(this.state.opt_1 === 1){
      arr = [packagesArr[0],packagesArr[7]];
    }
    if(this.state.opt_1 === 2){
      arr = [packagesArr[1],packagesArr[4]];
    }
    if(this.state.opt_1 === 3){
      arr = [packagesArr[2],packagesArr[5]];
    }

    if(this.state.opt_1 === 1 && this.state.opt_2 === 1){
      arr = [packagesArr[1],packagesArr[7]];
    }
    if(this.state.opt_1 === 2 && this.state.opt_2 === 1){
      arr = [packagesArr[2],packagesArr[5]];
    }
    if(this.state.opt_1 === 3 && this.state.opt_2 === 1){
      arr = [packagesArr[2],packagesArr[5]];
    }


    return <>
      {arr.length &&
      <>
      <h3 style={{fontSize:'28px'}}>Vi anbefaler</h3>
      <Row noGap style={{width:'100%',maxWidth:'740px',marginLeft:'auto',marginRight:'auto',marginTop:'-10px'}}>
        {this.renderPackageCards(arr)}
      </Row>
      </>
      }
    </>;

  }

  renderPackageCards(arr){
    return <>
    {arr.map(n => (
      <Col width="100" medium="50">
        <Link href={"/pakke/"+n.id} style={{width:'100%'}}>
                    <Card className="package-home-card elevation-6 elevation-hover-12 elevation-pressed-24 elevation-transition">
                      <CardHeader
                        className="no-border"
                        valign="bottom"
                        style={{ backgroundImage: this.bgImgStyle(n.img) }}
                      />
                      <CardContent>
                        <h2>
                        {n.name}
                        </h2>
                        <h4>
                        Lei fra <b>kr. {n.price},-</b>
                        </h4>
                        <p>{n.desc}</p>
                      </CardContent>
                    </Card>
        </Link>
      </Col>
    ))}
    </>
  }

  render() {
    return (
  <Page>
    <PlNavbar/>
      <div style={{width:'100%',maxWidth:'540px',marginLeft:'auto',marginRight:'auto'}}>
        <Block>
              <p className="text-align-center">Vi hjelper deg å finne en pakke som passer perfekt for ditt arrangement! Svar på spørsmålene under, så vil vi foreslå den pakken som passer best.</p>

              <h3>Hvor stort er ditt arrangement?</h3>
              <Card style={{width:'100%',maxWidth:'540px',marginLeft:'auto',marginRight:'auto'}}>
                <Row noGap>
                  <Col onClick={()=>this.setState({opt_1:1})} className={this.returnClassNameActiveOpt1(1)} width="33" style={{backgroundImage: "url('https://pl.iwd.no/www/img/home/s-group.svg')"}}>
                      <p>Lite</p>
                      <span>Mindre enn 30</span>
                  </Col>
                  <Col onClick={()=>this.setState({opt_1:2})} className={this.returnClassNameActiveOpt1(2)} width="33" style={{backgroundImage: "url('https://pl.iwd.no/www/img/home/m-group.svg')"}}>
                      <p>Medium</p>
                      <span>ca. 60</span>
                  </Col>
                  <Col onClick={()=>this.setState({opt_1:3})} className={this.returnClassNameActiveOpt1(3)} width="33" style={{backgroundImage: "url('https://pl.iwd.no/www/img/home/l-group.svg')"}}>
                      <p>Stort</p>
                      <span>Flere enn 80</span>
                  </Col>
                </Row>
              </Card>

              <h3>Hvor er ditt arrangement?</h3>
              <Card style={{width:'100%',maxWidth:'540px',marginLeft:'auto',marginRight:'auto'}}>
                <Row noGap>
                  <Col onClick={()=>this.setState({opt_2:1})} className={this.returnClassNameActiveOpt2(1)} width="50" style={{backgroundImage: "url('https://pl.iwd.no/www/img/home/tree.svg')", backgroundSize: 'auto 44%'}}>
                      <p>Utendørs</p>
                      <span>Ditt arrangement skal være utendørs</span>
                  </Col>
                  <Col onClick={()=>this.setState({opt_2:2})} className={this.returnClassNameActiveOpt2(2)} width="50" style={{backgroundImage: "url('https://pl.iwd.no/www/img/home/house.png')", backgroundSize: 'auto 44%'}}>
                      <p>Innendørs</p>
                      <span>Ditt arrangement skal være innendørs</span>
                  </Col>
                </Row>
              </Card>
        </Block>
        </div>
        {this.renderResults()}
    <PlBottomBar/>

  </Page>);
  }
};