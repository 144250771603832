import React from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Page,
    Link,
    Row,
    Col,
} from 'framework7-react';

import {API_URL} from '../../../api';

import PlNavbar  from '../../shared/navbar/navbar';
import PlBottomBar  from '../../shared/bottomBar/bottomBar';

import './main.css'

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      sound:[],
      lights:[],
      party_package:[]
    }
  }
  relativeUrl(path){
    return this.$f7route.url + path;
  }
  bgImgStyle(path){
    return 'url('+path+')';
  }
  render() {
    return (
  <Page>
    <PlNavbar/>

    <h3>Lydpakker</h3>

    {this.state.loading
          ? (
          <>
            <Row noGap style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto'}}>
              {[1,2,3].map(n => (
                <Col key={n} width="100" medium="50" large="33">
                  <Card className="skeleton-text skeleton-effect-pulse">
                    <CardHeader className="no-border" style={{ height: '240px', backgroundColor: '#ccc'}}/>
                    <CardContent>
                      <h2>Lorem ibsum</h2>
                      <h4>Quisque eget vestibulum nulla.</h4>
                      <p>Quisque eget vestibulum nulla. Quisque quis dui quis ex ultricies efficitur vitae non felis. Phasellus quis nibh hendrerit...</p>
                    </CardContent>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
            )
          : (
          <>
            <Row noGap style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto',marginBottom:'26px'}}>
              {this.state.sound.map(n => (
                <Col key={n.id} width="100" medium="50" large="33">
                  <Link href={"/pakke/"+n.id} style={{width:'100%'}}>
                    <Card className="package-home-card elevation-6 elevation-hover-12 elevation-pressed-24 elevation-transition">
                      <CardHeader
                        className="no-border"
                        valign="bottom"
                        style={{ backgroundImage: this.bgImgStyle(n.img) }}
                      />
                      <CardContent>
                        <h2>
                        {n.name}
                        </h2>
                        <h4>
                        Lei fra <b>kr. {n.price},-</b>
                        </h4>
                        <p>{n.desc}</p>
                      </CardContent>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </> 
    )
  }

  <div className="coloredDiv" style={{backgroundColor:'#dfe4ea'}}>
    <h3>Lyspakker</h3>

    {this.state.loading
          ? (
          <>
            <Row noGap style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto'}}>
              {[1,2,3].map(n => (
                <Col key={n} width="100" medium="50" large="33">
                  <Card className="skeleton-text skeleton-effect-pulse">
                    <CardHeader className="no-border" style={{ height: '240px', backgroundColor: '#ccc'}}/>
                    <CardContent>
                      <h2>Lorem ibsum</h2>
                      <h4>Quisque eget vestibulum nulla.</h4>
                      <p>Quisque eget vestibulum nulla. Quisque quis dui quis ex ultricies efficitur vitae non felis. Phasellus quis nibh hendrerit...</p>
                    </CardContent>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
            )
          : (
          <>
            <Row noGap style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto',marginBottom:'26px'}}>
              {this.state.lights.map(n => (
                <Col key={n.id} width="100" medium="50" large="33">
                  <Link href={"/pakke/"+n.id} style={{width:'100%'}}>
                    <Card className="package-home-card elevation-6 elevation-hover-12 elevation-pressed-24 elevation-transition">
                      <CardHeader
                        className="no-border"
                        valign="bottom"
                        style={{ backgroundImage: this.bgImgStyle(n.img) }}
                      />
                      <CardContent>
                        <h2>
                        {n.name}
                        </h2>
                        <h4>
                        Lei fra <b>kr. {n.price},-</b>
                        </h4>
                        <p>{n.desc}</p>
                      </CardContent>
                    </Card>
                  </Link>
                </Col>
              ))}
            </Row>
          </> 
    )
  }
  </div>

  <div className="coloredDiv" style={{backgroundColor:'#ced6e0'}}>
    <h3>Lyd- og lyspakker</h3>

    {this.state.loading
          ? (
          <>
            <Row noGap style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto'}}>
              {[1,2,3].map(n => (
                <Col key={n} width="100" medium="50" large="33">
                  <Card className="skeleton-text skeleton-effect-pulse">
                    <CardHeader className="no-border" style={{ height: '240px', backgroundColor: '#ccc'}}/>
                    <CardContent>
                      <h2>Lorem ibsum</h2>
                      <h4>Quisque eget vestibulum nulla.</h4>
                      <p>Quisque eget vestibulum nulla. Quisque quis dui quis ex ultricies efficitur vitae non felis. Phasellus quis nibh hendrerit...</p>
                    </CardContent>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
            )
          : (
          <>
            <Row noGap style={{width:'100%',maxWidth:'1040px',marginLeft:'auto',marginRight:'auto',marginBottom:'26px'}}>
              {this.state.party_package.map(n => (
                <Col key={n.id} width="100" medium="50" large="33">
                  <Link href={"/pakke/"+n.id} style={{width:'100%'}}>
                    <Card className="package-home-card elevation-6 elevation-hover-12 elevation-pressed-24 elevation-transition">
                      <CardHeader
                        className="no-border"
                        valign="bottom"
                        style={{ backgroundImage: this.bgImgStyle(n.img) }}
                      />
                      <CardContent>
                        <h2>
                        {n.name}
                        </h2>
                        <h4>
                        Lei fra <b>kr. {n.price},-</b>
                        </h4>
                        <p>{n.desc}</p>
                      </CardContent>
                    </Card>
                  </Link>
                </Col>
              ))}
               <Col width="0" medium="33"></Col>
            </Row>
          </> 
        )
      }
  </div>

    <PlBottomBar/>

  </Page>);
  }

  componentDidMount(){
    this.loadXhr();
  }

  loadXhr() {
    fetch(API_URL+'/api/store/get_categories')
    .then(response => {
      return response.json()
    })
    .then(data => {
      
      this.setState({
        loading:false,
        error:false,
        sound:data.sound,
        lights:data.lights,
        party_package:data.party_package
      });

    })
    .catch(err => {
      this.setState({
        loading:false,
        error:true,
      });
    })

}
};