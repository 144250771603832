import React from 'react';
import { Page, Block, BlockTitle } from 'framework7-react';

import PlNavbar  from '../shared/navbar/navbar';
import PlBottomBar  from '../shared/bottomBar/bottomBar';

export default class extends React.Component {
  render() {
    return (
  <Page>
    <PlNavbar/>
    <div style={{width:'100%',maxWidth:'740px',marginLeft:'auto',marginRight:'auto'}}>
      <BlockTitle large>Om PartyLyd</BlockTitle>
      
      <Block>
        <p>PartyLyd er en fresh utleiebedrift som streber etter å gjøre PA tilgjengelig til enhver fest! Fokuset vårt er pris, service og høy partyfaktor. Vi er lidenskapelig opptatt av å levere kvalitetshøyttalere.</p>
        <p>Hvorfor startet vi PartyLyd? Ideen og drivet bak bedriften kommer av erfaringer med hjemmefester der kvaliteten på lydanlegget har gjort en stor forskjell. Musikk har alltid mye å si for stemningen, men folk flest som arrangerer hjemmefest er ikke villige til å betale i dyre dommer for leie av et skikkelig anlegg. Det slipper du hos oss. Her får du utstyret du trenger for å gjøre ditt party legendarisk, uten å måtte tømme lommeboka!</p>
        <p>PA-anlegget og utstyret som skal gjøre bryllupet perfekt eller bursdagen ekstra minnerik, står klart hos oss nå mens du leser.</p>
      </Block>

      <BlockTitle medium>Hørt om vår utrolige Prisgaranti?</BlockTitle>
      <Block>
        <p>Hos PartyLyd satser vi alt på at vi har det billigste tilbudet, og derfor gir vi deg som kunde <b>prisgaranti</b>. Det vil si at dersom du finner tilsvarende utleieprodukter hos våre konkurrenter til lavere pris, matcher vi den og gir deg ytterligere 10% avslag!</p>
        <p>Vi jobber aktivt for å utvide vårt tilbud av produkter. Du er alltid velkommen til å kontakte oss, på telefon eller e-post, dersom du har noen spørsmål!</p>
      </Block>
    </div>

    <PlBottomBar/>

  </Page>);
  }
};